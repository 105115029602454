import React from "react";
import GameFrom from "../components/GameForm"

const DashBoard = (props) => {
    // console.log(props.location.search.replace('?',''))
    return (

        <GameFrom />

    );
};

export default DashBoard;
