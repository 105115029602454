import React, { useState } from "react";
import firebase from "../firebase/firebase";
// import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Alert, Spinner } from 'react-bootstrap'

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState()
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState()

    const [loader, setLoader] = useState(false)

    const handleSubmit = (e) => {
        setLoader(true)
        console.log("submit");

        const auth = firebase.auth();
        auth.signInWithEmailAndPassword(email, password)
            .then((cred) => {
                setLoader(false)
                props.history.push("/dashboard");
            })
            .catch((err) => {
                setLoader(false)

                if (email) {
                    setPasswordError(err.message)
                } else {
                    setEmailError(err.message)

                }
            });
        e.preventDefault();
    };

    if (loader) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyItems: 'center', flexDirection: 'column' }}>
                <Spinner

                    animation="border"
                    variant="primary"
                    size="lg"
                />

            </div>

        )
    } else {

        return (

            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100 p-l-85 p-r-85 p-t-55 p-b-55">
                        <form className="login100-form validate-form flex-sb flex-w" onSubmit={handleSubmit}>
                            <span className="login100-form-title p-b-20">
                                Sign in your account
                        </span>

                            <span className="email txt1 ">
                                Email
                        </span>
                            <div className="wrap-input100 validate-input m-b-36" data-validate="Username is required">
                                <input className="input100" name="email" placeholder="hello@example.com"
                                    type="email"
                                    id="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError(null)
                                    }}
                                />
                                <span className="focus-input100"></span>
                                {emailError ?
                                    <Alert variant='danger'>
                                        <p style={{ fontSize: '12px' }}>
                                            {emailError}

                                        </p>
                                    </Alert>
                                    : null}
                            </div>

                            <span className="txt1  email">
                                Password
                </span>
                            <div className="wrap-input100 validate-input m-b-12" data-validate="Password is required">
                                <span className="btn-show-pass">
                                    <i className="fa fa-eye"></i>
                                </span>
                                <input className="input100" type="password" name="pass"
                                    id="password"
                                    placeholder="Enter the password"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                                <span className="focus-input100"></span>
                                {passwordError ?
                                    <Alert variant='danger'>
                                        <p style={{ fontSize: '12px' }}>
                                            {passwordError}

                                        </p>
                                    </Alert>
                                    : null}
                            </div>

                            <div className="flex-sb-m w-full p-b-48">
                                <div className="contact100-form-checkbox">

                                    <label className="rememberme">
                                        <input type="checkbox" name="remember" /> Remember me
                          </label>
                                </div>

                                <div>
                                    <a href="#" className="txt3">
                                        Forgot Password?
                        </a>
                                </div>
                            </div>

                            <div className="container-login100-form-btn">

                                <button type="submit" className="login100-form-btn">
                                    Sign Me In
                            </button>

                            </div>

                        </form>
                        <div className="account rememberme">
                            <span> Don't have an account?</span>&nbsp;<span className="signup"> <Link to="/signup">Sign Up</Link></span>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
};

export default Login;


     // <section classNameName="body-sign">
            //     <div classNameName="center-sign">
            //         {/* <a href="/" classNameName="logo pull-left"></a> */}

            //         <div classNameName="panel panel-sign">
            //             <div classNameName="panel-title-sign mt-xl text-center">
            //                 <h2 classNameName="title  text-bold m-none">
            //                     <i classNameName="fa fa-user mr-xs"></i> Sign in your
            //                 account
            //             </h2>
            //             </div>
            //             <div classNameName="panel-body">
            //                 <form onSubmit={handleSubmit}>
            //                     <div classNameName="form-group mb-lg">
            //                         <label>Username</label>
            //                         <div classNameName="input-group input-group-icon">
            //                             <input
            //                                 type="email"
            //                                 id="email"
            //                                 placeholder="Enter Email Adress"
            //                                 onChange={(e) => {
            //                                     setEmail(e.target.value);
            //                                     setEmailError(null)
            //                                 }}
            //                                 classNameName="form-control input-lg"
            //                             />


            //                         </div>
            //                         {emailError ?
            //                             <Alert variant='danger'>
            //                                 {emailError}
            //                             </Alert>
            //                             : null}
            //                     </div>

            //                     <div classNameName="form-group mb-lg">
            //                         <div classNameName="clearfix">
            //                             <label classNameName="pull-left">
            //                                 Password
            //                         </label>

            //                         </div>
            //                         <div classNameName="input-group input-group-icon">
            //                             <input
            //                                 type="password"
            //                                 id="password"
            //                                 placeholder="Enter the password"
            //                                 onChange={(e) => {
            //                                     setPassword(e.target.value);
            //                                 }}
            //                                 classNameName="form-control input-lg"
            //                             />

            //                         </div>
            //                         {passwordError ?
            //                             <Alert variant='danger'>
            //                                 {passwordError}
            //                             </Alert>
            //                             : null}
            //                     </div>

            //                     <div classNameName="row">
            //                         <div classNameName="col-sm-8">
            //                             <div classNameName="checkbox-custom checkbox-default">
            //                                 <input
            //                                     id="RememberMe"
            //                                     name="rememberme"
            //                                     type="checkbox"
            //                                 />
            //                                 <label>Remember Me</label>
            //                             </div>
            //                         </div>
            //                         <div classNameName="col-sm-4 text-right">
            //                             <label

            //                             >
            //                                 Forgot Pasword?
            //                         </label>
            //                         </div>
            //                         <button
            //                             type="submit"
            //                             classNameName="btn btn-primary btn-block btn-lg visible-xs mt-lg"
            //                         >
            //                             Sign In
            //                     </button>
            //                     </div>

            //                     <p classNameName="text-left top-setting">
            //                         Don't have an account yet?{" "}
            //                         <Link to="/signup">Sign Up!</Link>
            //                     </p>
            //                 </form>
            //             </div>
            //         </div>
            //     </div>
            // </section>