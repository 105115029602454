import React from 'react'
import firebase from '../firebase/firebase'
import { toUpper, toLower } from 'lodash'
import { PlayerContext } from '../context/playerProvider'

import { Modal } from 'react-bootstrap'




const AddBattlePlayerModal = ({ members, closePlayerModal, legends, url, show, color }) => {

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')

    const emailInput = React.useRef()

    const { cookies, setCookiesChanged } = React.useContext(PlayerContext)


    const db = firebase.firestore()

    const addPlayer = () => {
        if (name && email) {
            if (validateEmail(email)) {
                emailInput.current.style.borderColor = "black"

                if (members.length < 20) {


                    db.collectionGroup(`members`)
                        .where("gameId", '==', url)
                        .orderBy("email")
                        .startAt(toUpper(email))
                        .endAt(toLower(email) + "\uf8ff")
                        .get()
                        .then((snapshot) => {
                            let record = false;
                            snapshot.docs.forEach((doc) => {
                                if (toLower(doc.data().email) === toLower(email)) {
                                    let id = doc.id
                                    record = { ...doc.data(), id };
                                }
                            });

                            const legendObjects = legends.map(legend => {
                                const legendId = legend.id
                                const frequency = 0
                                return { legendId, frequency }
                            })

                            if (record) {
                                if (record.playing === true) {
                                    alert("Player already exists")
                                } else {

                                    db.collection(`games/${url}/members`)
                                        .doc(record.id)
                                        .set({
                                            name: name,
                                            email: email,
                                            gameId: url,
                                            type: 'added',
                                            playing: true,
                                            reactions: [],
                                            score: 0,
                                            legends: [...legendObjects]
                                        }, { merge: true })


                                }

                            } else {

                                db.collection(`games/${url}/members`)
                                    .doc()
                                    .set(
                                        {
                                            name: name,
                                            email: email,
                                            gameId: url,
                                            type: 'added',
                                            playing: true,
                                            score: 0,
                                            legends: [...legendObjects]
                                        },
                                        { merge: true }
                                    )

                            }
                            closePlayerModal()
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    alert('Member are filled')
                }

            } else {
                emailInput.current.style.borderColor = 'red'
                emailInput.current.value = email
                setEmail('invalid')

            }

        } else {
            alert("Enter Name and email");
        }
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    console.log(email)


    return (
        <Modal
            show={show}
            onHide={closePlayerModal}
            backdrop="static"
            keyboard={false}
        >

            <Modal.Body>
                <div id="modalCenter" className="modal-block mfp-hide">
                    <section className="panel">
                        <div className={`panel-body card matchcard cardborder${color}`}>
                            <div className="modal-wrapper p-4">
                                <div className="modal-text text-left    ">
                                    <h4 style={{ fontWeight: 'bold' }}>Enter Player Detail</h4>


                                    <input type="text" placeholder="Enter player Name" maxLength="30" className="Addmodelinput1" onChange={(e) => { setName(e.target.value) }}
                                    />


                                    <input type="text" ref={emailInput} placeholder="Player Email" className="Addmodelinput2" onChange={(e) => { setEmail(e.target.value) }} />
                                    {email === 'invalid' ? 
                                    <p style={{fontSize:12 , color:'red'}}>Email is invalid!</p>
                                    :null}


                                    <button className={`btn btn-default modelYesbtn btn${color}yes`} onClick={() => { addPlayer() }}>Add</button>
                                    <button className="btn btn-default modelNobtn" onClick={closePlayerModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </Modal.Body>

        </Modal>
    )
}

export default AddBattlePlayerModal
