import React from "react";
import firebase from "../firebase/firebase";
import { UserContext } from "../context/userProvider";
import { TimerContext } from "../context/timerProvider";

import { Spinner } from "react-bootstrap";
import { toInteger } from "lodash";



const ResetTimerInDatabase = (url) => {
    console.log('reseting timer')
    const db = firebase.firestore();
    db.collection("games")
        .doc(url)
        .get()
        .then((snapshot) => {
            db.collection("games").doc(url).set(
                {
                    time: snapshot.data().resetTime
                },
                { merge: true }
            );
        });
};



const increaseTimerByTenSec = (timer, url) => {
    const db = firebase.firestore();

    db.collection("games")
        .doc(url)
        .set(
            {
                time: timer + 10
            },
            { merge: true }
        );
}

const GameTimer = (props) => {
    const { url , gameId } = props;

    const [updatedTimer, setUpdatedTimer] = React.useState({ hours: 0, minutes: 0, seconds: 0 })
    // const [userId, setUserId] = React.useState();

//  const startTimer = firebase.functions().httpsCallable('startTimer')


    const { user } = React.useContext(UserContext);
    const { timer ,setIsActive , hours , minutes ,seconds , setUrl } = React.useContext(TimerContext);

    const db = firebase.firestore();




    React.useEffect(()=>{
        // const startTimer = firebase.functions().httpsCallable('startTimer');
        // startTimer({url})
        // .then(result => {
        //     console.log(result)
        // })
        setUrl(url)
    },[])

    // React.useEffect(() => {
    
    //     getTimeFromDataBase(setTimer, url);
    //     changeFormat(timer);
    

    //     if (!userId) {
    //         db
    //             .collection(`games`)
    //             .doc(url)
    //             .get()
    //             .then((snapshot) => {
    //                 // console.log(snapshot.data());
    //                 setUserId(snapshot.data().uid);
    //             });
    //     }

    //     if (isactive) {
    //         var interval = setInterval(() => {
    //             decreaseTimer(timer, url);
    //             setTimer(timer - 1);
    //         }, 1000);
    //     }
    //     if (timer === 0) {
    //         setIsActive(false);
    //         clearInterval(interval);
    //         console.log("clear");
    //     }

    //     return () => clearInterval(interval);
    // }, [timer, isactive, url, userId, db]);


    const CheckMinutes = (e) => {

        if (!(/[0-9]$/.test(e.target.value))) {
            e.target.value = ''

        }
        else {
            const updatedMinutes = toInteger(e.target.value)
            if (updatedMinutes <= 60 * 24) {

                updatedTimer.minutes = updatedMinutes
                setUpdatedTimer(updatedTimer)
            } else {
                e.target.value = updatedTimer.minutes
            }

        }


    }


    const plusTimer = (timeToPlus) => {
        db.collection("games")
            .doc(url)
            .set({
                time: timer + timeToPlus

            }, { merge: true })

    }

    const minusTimer = (timeToMinus) => {
        db.collection("games")
            .doc(url)
            .set({
                time: timer - timeToMinus

            }, { merge: true })




    }


    const saveExtraMinutes = (e) => {
        const totalMinutes = toInteger(minutes) + toInteger(hours * 60)
        console.log(typeof totalMinutes)
        const scoreInSeconds = (updatedTimer.minutes * 60)
        if (e.target.name === 'plus') {
            plusTimer(scoreInSeconds)

        } else {
            if (totalMinutes >= updatedTimer.minutes) {
                minusTimer(scoreInSeconds)


            }
        }

    }


    if (timer && timer !== 'waiting') {
        return (
            <>
                {/* <div class="timingbtn">
                    00:00:00
                        </div> */}
                <div className="timingbtn">
                    {hours}:{minutes}:{seconds}
                </div>


                {user && user.uid === gameId?
                    <>

                        <div className="timmeredit">

                            <button className="timmerbtnplus" name="minus" onClick={saveExtraMinutes}>-</button>
                            <input className="inputtimmer" placeholder="MM" onChange={CheckMinutes} />
                            <button className="timmerbtn" name="plus" onClick={saveExtraMinutes}>+</button>&nbsp;

                        </div>
                        <div className="col-md-12 Gamificationbtn">
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btntext"
                                        onClick={() => {
                                            setIsActive(true);
                                        }}
                                    >
                                        Start&nbsp;
                                </button>

                                {/* <button className="btntext"
                                        onClick={()=>{startTimer({url:url,time:timer})}}
                                    >
                                        start
                                </button> */}

                                    <button className="btntext"
                                        onClick={() => {
                                            ResetTimerInDatabase(url);
                                            setIsActive(false);
                                        }}
                                    >
                                        Reset
                                 </button>
                                </div>
                                <div className="col-md-6">
                                  
                                    <button className="btntext"
                                        onClick={() => {
                                            setIsActive(false);
                                        }}
                                    >
                                        Pause
                                </button>
                                </div>

                            </div>

                        </div>
                    </>
                    :
                    null
                }

                <br />
                <br />
            </>

        );
    } else if (timer === 'waiting') {
        return <Spinner animation="grow" variant="primary" />;
    } else {
        return null
    }
};

export default GameTimer;

   // <div classNameName="col-md-12 spaces-bottom">
            //     <button type="button" classNameName="btn btn-primary btn-lg btn-block">{hours} h : {minutes} min : {seconds} sec</button>
            //     {user && user.uid === userId ?
            //         <div classNameName="row">
            //             <div classNameName="col">
            //                 <button type="button"
            //                     onClick={() => {
            //                         setIsActive(true);
            //                     }}
            //                     classNameName="btn btn-light timer-width top-space" > Start Timer</button>
            //             </div>
            //             <div classNameName="col">
            //                 <button type="button"
            //                     onClick={() => {
            //                         ResetTimerInDatabase(url);
            //                         setIsActive(false);
            //                     }}
            //                     classNameName="btn btn-light top-space">Reset</button>
            //             </div>
            //             <div classNameName="col">
            //                 <button type="button"
            //                     onClick={() => {
            //                         increaseTimerByTenSec(timer, url)
            //                     }}
            //                     classNameName="btn btn-light top-space">+10Sec</button>
            //             </div>
            //             <div classNameName="col">
            //                 <button type="button"
            //                     onClick={() => {
            //                         setIsActive(false);
            //                     }}
            //                     classNameName="btn btn-light top-space" > Pause</button>
            //             </div>
            //         </div>
            //         : null}



            // </div>