import React, { useState } from "react";
import firebase from "../firebase/firebase";
import { Link } from "react-router-dom";
import { Alert, Spinner } from 'react-bootstrap'

const SignUp = (props) => {
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [emailError, setEmailError] = useState()
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState()
    const db = firebase.firestore()

    const [loader, setLoader] = useState(false)


    const handleSubmit = async (e) => {
        console.log("submit");
        e.preventDefault();


        const auth = firebase.auth();

        if (userName) {
            setLoader(true)

            auth.createUserWithEmailAndPassword(email, password)
                .then(result => {

                    auth.signInWithEmailAndPassword(email, password).then((cred) => {

                        db.collection('admin')
                            .doc(cred.user.uid)
                            .set({
                                name: userName
                            }, { merge: true })

                        props.history.push("/dashboard");
                    })
                })
                .catch(err => {
                    setLoader(false)


                    if (email) {
                        setPasswordError(err.message)

                    }
                    else {
                        setEmailError(err.message)
                    }


                })
        } else {
            setUserNameError('Enter User Name')

        }



    };

    if (loader === true) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Spinner
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                    animation="border"
                    variant="primary"
                    size="lg"
                />

            </div>
        )

    } else {


        return (
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100 p-l-85 p-r-85 p-t-55 p-b-55">
                        <form className="login100-form validate-form flex-sb flex-w" onSubmit={handleSubmit}>
                            <span className="login100-form-title p-b-20">
                                Sign up
                    </span>

                            <span className="email txt1 ">
                                User name
                            </span>
                            <div className="wrap-input100 validate-input m-b-36" data-validate="Username is required" >
                                <input className="input100" type="text" name="username" placeholder="User name" onChange={(e) => { setUserName(e.target.value); setUserNameError(null) }} />
                                <span className="focus-input100"></span>
                                {userNameError ?
                                    <Alert variant='danger'>
                                        <p style={{ fontSize: '12px' }}>
                                            {userNameError}

                                        </p>
                                    </Alert>
                                    : null}
                            </div>

                            <span className="email txt1 ">
                                Email
                    </span>
                            <div className="wrap-input100 validate-input m-b-36" data-validate="Username is required">
                                <input className="input100" name="email" placeholder="hello@example.com"
                                    type="email"
                                    id="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError(null)
                                    }}
                                />
                                <span className="focus-input100"></span>
                                {emailError ?
                                    <Alert variant='danger'>
                                        <p style={{ fontSize: '12px' }}>
                                            {emailError}

                                        </p>
                                    </Alert>
                                    : null}
                            </div>

                            <span className="txt1  email">
                                Password
            </span>
                            <div className="wrap-input100 validate-input m-b-12" data-validate="Password is required">
                                <span className="btn-show-pass">
                                    <i className="fa fa-eye"></i>
                                </span>
                                <input className="input100" type="password" name="pass"
                                    id="password"
                                    placeholder="Enter the password"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                                <span className="focus-input100"></span>
                                {passwordError ?
                                    <Alert variant='danger' >
                                        <p style={{ fontSize: '12px' }}>
                                            {passwordError}


                                        </p>
                                    </Alert>
                                    : null}
                            </div>



                            <div className="container-login100-form-btn">

                                <button type="submit" className="login100-form-btn">
                                    Sign Me In
                        </button>

                            </div>

                        </form>
                        <div className="account rememberme">
                            <span> Already have an account?
?</span>&nbsp;<span className="signup"> <Link to="/">Sign In</Link></span>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
};

export default SignUp;

 // <section classNameName="body-sign">
            //     <div classNameName="center-sign">
            //         <div classNameName="panel panel-sign">
            //             <div classNameName="panel-title-sign mt-xl text-center">
            //                 <h2 classNameName="title  text-bold m-none">
            //                     <i classNameName="fa fa-user mr-xs"></i> Sign up
            //             </h2>
            //             </div>
            //             <div classNameName="panel-body">
            //                 <form onSubmit={handleSubmit}>
            //                     <div classNameName="form-group mb-lg">
            //                         <label>Username</label>
            //                         <div classNameName="input-group input-group-icon">
            //                             <input
            //                                 name="username"
            //                                 type="text"
            //                                 classNameName="form-control input-lg"
            //                             />
            //                             <span classNameName="input-group-addon">
            //                                 <span classNameName="icon icon-lg">
            //                                     <i classNameName="fa fa-user"></i>
            //                                 </span>
            //                             </span>
            //                         </div>
            //                     </div>

            //                     <div classNameName="form-group mb-lg">
            //                         <label>Email</label>
            //                         <div classNameName="input-group input-group-icon">
            //                             <input
            //                                 type="email"
            //                                 id="email"
            //                                 placeholder="Enter Email Address"
            //                                 onChange={(e) => {
            //                                     setEmail(e.target.value)
            //                                     setEmailError(null)
            //                                 }}
            //                                 classNameName="form-control input-lg"
            //                             />

            //                         </div>
            //                         {emailError ?
            //                             <Alert variant='danger'>
            //                                 {emailError}
            //                             </Alert>
            //                             : null}
            //                     </div>

            //                     <div classNameName="form-group mb-lg">
            //                         <div classNameName="clearfix">
            //                             <label classNameName="pull-left">
            //                                 Password
            //                         </label>

            //                         </div>
            //                         <div classNameName="input-group input-group-icon">
            //                             <input
            //                                 type="password"
            //                                 id="password"
            //                                 placeholder="Enter the password"
            //                                 onChange={(e) => {
            //                                     setPassword(e.target.value);
            //                                 }}
            //                                 classNameName="form-control input-lg"
            //                             />

            //                         </div>
            //                         {passwordError ?
            //                             <Alert variant='danger'>
            //                                 {passwordError}
            //                             </Alert>
            //                             : null}
            //                     </div>

            //                     <div classNameName="row">
            //                         <div classNameName="col-sm-8"></div>

            //                         <button
            //                             type="submit"
            //                             classNameName="btn btn-primary btn-block btn-lg visible-xs mt-lg"
            //                         >
            //                             Sign Me Up
            //                     </button>
            //                     </div>

            //                     <p classNameName="text-left top-setting">
            //                         Already have an account?{" "}
            //                         <Link to="/">Sign In</Link>
            //                     </p>
            //                 </form>
            //             </div>
            //         </div>
            //     </div>
            // </section>