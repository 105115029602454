import React, { useState, useCallback, useEffect, useContext } from "react";
import Member from './Member'
import firebase from "../firebase/firebase";
import { find, remove, orderBy, sortBy, indexOf, merge } from "lodash";

import AddTeamPlayerModal from "./AddTeamPlayerModal";
import { UserContext } from '../context/userProvider'
import { PlayerContext } from '../context/playerProvider'

import {Spinner} from 'react-bootstrap'
import alertify from 'alertifyjs'
import 'alertifyjs/build/css/alertify.css';





const TeamBoard = (props) => {
    const { team, url, color, legends, div, memberLegendsColor, addbutton , gameId} = props;

    const { user } = useContext(UserContext);

    const { cookies, setCookiesChanged, cookiesChanged } = useContext(PlayerContext)



    const [members, setMembers] = useState();
    const [openedModal, setModal] = useState();
    const [legendsLength, setLegendsLength] = useState(0)
    const [membersUpdated , setMembersUpdated]  = useState(false)

    const openAddPlayerModal = (id) => {
        setModal(id)
    }

    const closeAddPlayerModal = () => {
        setModal(null)
    }
    const db = firebase.firestore();

    const getMembersFromDB = useCallback(() => {
        let teamMembers = [];
        let unsubscribe;
        if(team){

        unsubscribe = db
            .collection(`games/${url}/teams/${team.id}/members`)
            .where('playing', '==', true)
            .onSnapshot((snapshot) => {
                const changes = snapshot.docChanges();
                changes.forEach((change) => {
                    if (change.type === "added") {
                        const data = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        teamMembers.push(data);
                    } else if (change.type === "modified") {
                        const memberPreviousState = remove(teamMembers, (o) => {
                            return o.id === change.doc.id;
                        });
                        const changeData = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };

                        // alertGifies(changeData, memberPreviousState[0])
                        showAlertOfEmote(changeData, memberPreviousState[0])

                        teamMembers.push(changeData);
                    } else if (change.type === "removed") {
                        remove(teamMembers, (o) => {
                            return o.id === change.doc.id;
                        });

                    }
                    teamMembers = orderBy(teamMembers, ["score"], ['desc']);

                });
                    setMembers(teamMembers);


            });

        return unsubscribe;
    }

    }, [url, db, team, setMembers]);

    const showAlertOfEmote = (changedData, membersPreviousState) => {

        const reactions = changedData.reactions
        const previousReactions = membersPreviousState.reactions

        let emoji;

        if (reactions) {
            if (previousReactions) {

                if (reactions.length > previousReactions.length) {
                    emoji = reactions[reactions.length - 1]
                    alertify.message(`Admin Gave ${emoji} to ${changedData.name}`)



                }

            } else {
                emoji = reactions[0]
                alertify.message(`Admin Gave ${emoji} to ${changedData.name}`)

            }



        }

    }

    const alertGifies = (changeData, membersPreviousState) => {

        const gifies = changeData.gifies
        console.log(gifies)
        const previousgifies = membersPreviousState.gifies

        if (previousgifies) {
            const lastModifiedGify = gifies[gifies.length - 1]

            const previousStateOfGify = find(previousgifies, (o) => { return o.id === lastModifiedGify.id })
            if (previousStateOfGify) {
                if (lastModifiedGify.frequency > previousStateOfGify.frequency) {

                    let gifyToShow = `admin sent <img src=${lastModifiedGify.url} width=150 height=150 /> to ${changeData.name}`
                    alertify.notify(gifyToShow)
                }

            } else {
                let gifyToShow = `admin sent <img src=${lastModifiedGify.url} width=150 height=150 /> to ${changeData.name}`
                alertify.notify(gifyToShow)
            }

        } else {
            if (gifies) {
                let gify = gifies[gifies.length - 1]
                let gifyToShow = `Admin gave <img src=${gify.url} width=150 height=150 /> to ${changeData.name}`
                alertify.notify(gifyToShow)

            }

        }

    }



    React.useEffect(() => {
        const unsubscribeMembers = getMembersFromDB();

        return () => {
            if(members && members.length){
            unsubscribeMembers();

            }
        };
    }, [getMembersFromDB]);


    const RemovedByAdmin = React.useCallback(() => {
        const player = cookies.get('teamplayer')
        if (player) {
            db.collection(`games/${url}/teams/${team.id}/members`)
                .doc(player.id)
                .get()
                .then((doc) => {
                    if (doc.data())
                        if (doc.data().playing === false) {
                            cookies.remove('teamplayer', { path: '/' })
                            setCookiesChanged(!cookiesChanged)

                        }
                })

        }


    }, [cookies, db, url, team])

    React.useEffect(() => {
        RemovedByAdmin()

    }, [RemovedByAdmin])


    const calculateLegendsLengthInDb = useCallback((legends) => {
        db.collection(`games/${url}/legends`)
            .get()
            .then(snapshot => {
                setLegendsLength(snapshot.docs.length)
            })
    },[db,url])

    const calculateMemberScore = useCallback(() => {
            calculateLegendsLengthInDb()
        if (members && legends && members.length && legends.length && legends.length === legendsLength) {
            let i = 1
        let teamScore = 0;
        const batch = db.batch()


            members.forEach((member) => {
                let membersCalculatedScore = 0;
               
                    const legendObjects = legends.map(legend => {
                        const legendId = legend.id
                        const findMemberLegend = find(member.legends, (o) => { return o.legendId === legendId })

                        if (findMemberLegend) {
                            return { legendId, frequency: findMemberLegend.frequency }

                        } else {
                            return { legendId, frequency: 0 }

                        }
                    })


                    legendObjects.forEach((legend) => {
                        const scoreLegend = find(legends, (o) => {
                            return o.id === legend.legendId;
                        });
                        if (scoreLegend) {
                            membersCalculatedScore += scoreLegend.points * legend.frequency;
                        }
                    })

                    console.log(legendObjects)

                    teamScore += membersCalculatedScore;



                        let memberRef  = db.collection(`games/${url}/teams/${team.id}/members`).doc(member.id)
                        batch.update(memberRef , {legends:[...legendObjects] , score:membersCalculatedScore})    
                        // .set(
                            //     {
                            //         legends: [...legendObjects],
                            //         score: membersCalculatedScore
                            //     },
                            //     { merge: true }
                            // );


                            // if(i === members.length){

                         

                            // }
                        
                            // i += 1  

                            console.log('runing')
                          
            });

            batch.commit().then(() => {
                db.collection(`games/${url}/teams`)
                .doc(team.id)
                .set(
                    {
                        score: teamScore
                    },
                    { merge: true }
                );   
                setMembersUpdated(true)
            })


        }
    }, [legends, members ,team, legendsLength , db , url , calculateLegendsLengthInDb])

    useEffect(() => {

        if(!membersUpdated){
        calculateMemberScore();

        }


    }, [calculateMemberScore])



    if(members){

    return (
        <div className={`col-md-12 col-lg-4 col-xl-4 col-sm-12 ${div}`}>
            {user && user.uid === gameId? (
                <div className="text-center gamification-right-button">
                    <button type="button" className={`playbtn ${addbutton}`} id={team ? team.id : 'a'} onClick={(e) => { openAddPlayerModal(e.target.id) }}>Add&nbsp;Player</button>
                    <AddTeamPlayerModal team={team} closePlayerModal={closeAddPlayerModal} openedPlayerModal={openedModal} legends={legends} url={url} color={color} members={members} />
                </div>
            )
                :
                (
                    null
                )
            }
            <div className={`team-border-${color}`}>
                <table className="table1">
                    <thead>

                        <tr>
                            <th className="headingthnum">#</th>
                            <th className="headingthName">Name</th>
                            {/* <th className="headingthScore">Score</th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {members.length && team
                            ? members.map((member, index) => (
                                <Member key={member.id} legends={legends} url={url} member={member} team={team} members={members} number={index + 1} color={color} memberLegendsColor={memberLegendsColor} gameId ={gameId} />
                            ))
                            : null}


                    </tbody>

                </table>
                <h5 className="playerpresent"> Players Present&nbsp;=&nbsp;{members ? members.length : 0} </h5>
            </div>
            <div>
            </div>
        </div >


    );
}
else {
    return (
        <div className={`col-md-12 col-lg-4 col-xl-4 col-sm-12 ${div}`}>

        <Spinner class="spinnercenter" animation="border" variant="primary" />
        </div>    )
}
};

export default TeamBoard;