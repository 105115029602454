import React, { useEffect } from 'react'
import { UserContext } from "../context/userProvider";
import { TimerContext } from "../context/timerProvider";
import { Spinner } from 'react-bootstrap'
import firebase from '../firebase/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Profile from '../assets/images/profile.png'
import { faUserCircle, faSignOutAlt, faExpand } from '@fortawesome/free-solid-svg-icons'


const signOutUser = () => {
    firebase
        .auth()
        .signOut()
        .then(() => {
            console.log("sign Out");
        })
        .catch((err) => {
            console.log("error");
        });
};

const NavBar = () => {
    const { user } = React.useContext(UserContext);
    const { url } = React.useContext(TimerContext);

    const db = firebase.firestore()
    const [userName, setUserName] = React.useState()
    const [GameCreatorId, setGameCreateorId] = React.useState()


    useEffect(async()=>{
        if(url){
            const game = await db.collection('games').doc(url).get()
            setGameCreateorId(game.data().uid)
        }
    },[url])

    useEffect(() => {

        if (user && user !== 'waiting') {
            db.collection('admin')
                .doc(user.uid)
                .onSnapshot(doc => {
                    if (doc.data()) {
                        setUserName(doc.data().name)


                    }


                })

        }





    }, [user, db])

    if (user === 'waiting') {
        return <Spinner animation="grow" variant="primary" />;

    } else if (user) {

        if(GameCreatorId && user.uid === GameCreatorId){
            return (
                <div id="navbar">
    
    
                    <nav className="infromationwelcom" >
                        {/* <FontAwesomeIcon className="fa-expand" icon={faExpand} /> */}
    
    
    
                        <div className="dropdown">
                            <span className="dropbtn">
                                <img src={Profile} alt="Avatar" class="avatar" />
                                <ul className="fa-ul">
    
                                    <div className="dropdown-content">
    
                                        <li>
                                            <a className="atageprofile" href="#" onClick={signOutUser}>  <FontAwesomeIcon icon={faSignOutAlt} /> Logout  </a>
                                        </li>
    
    
    
    
                                    </div>
                                </ul>
    
    
                            </span>
    
                        </div>
    
    
    
                        <p className="text1" > Hey, <strong>{userName}</strong> <br />Welcome </p>
    
    
                    </nav>
                </div>
    
    
    
            )
        }else if(!GameCreatorId){
            return (
                <div id="navbar">
    
    
                    <nav className="infromationwelcom" >
                        {/* <FontAwesomeIcon className="fa-expand" icon={faExpand} /> */}
    
    
    
                        <div className="dropdown">
                            <span className="dropbtn">
                                <img src={Profile} alt="Avatar" class="avatar" />
                                <ul className="fa-ul">
    
                                    <div className="dropdown-content">
    
                                        <li>
                                            <a className="atageprofile" href="#" onClick={signOutUser}>  <FontAwesomeIcon icon={faSignOutAlt} /> Logout  </a>
                                        </li>
    
    
    
    
                                    </div>
                                </ul>
    
    
                            </span>
    
                        </div>
    
    
    
                        <p className="text1" > Hey, <strong>{userName}</strong> <br />Welcome </p>
    
    
                    </nav>
                </div>
            )
        }else{
            return null
        }   

    } else {
        return null
    }


}

export default NavBar

  // <nav classNameName="navbar navbar-expand-lg navbar-light">
            //     <div classNameName="container">
            //         <button classNameName="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            //             <i classNameName="fa fa-bars"></i>
            //         </button>
            //         <div classNameName="collapse navbar-collapse" id="navbarSupportedContent">
            //             <ul classNameName="nav navbar-nav ml-auto">
            //                 {/*  <li classNameName="nav-item "> */}
            //                 <i classNameName="fas fa-expand"></i>
            //                 {/*   </li> */}
            //                 {/*       <li classNameName="nav-item">


            //             </li> */}

            //                 <div classNameName="greybox"></div>


            //                 <label style={{ marginTop: "20px" }}>Hey <h6 style={{ fontWeight: "bold" }}>Johan</h6></label>

            //             </ul>
            //         </div>
            //     </div>
            // </nav>


