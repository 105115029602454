import React , {useState , useCallback , useEffect , createContext , useContext}from 'react'
import firebase from '../firebase/firebase'
import {PlayerContext} from './playerProvider'
import {UserContext} from './userProvider'
import Cookies from 'universal-cookie'



export const TimerContext = createContext()

export const TimerProvider = (props) => {

    const [timer, setTimer] = useState('waiting');
    const [isactive, setIsActive] = useState(false);
    const [url , setUrl] = useState()
    const cookies = new Cookies()
    const {user} = useContext(UserContext)

    const db = firebase.firestore();
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();



    const decreaseTimer = () => {
        const db = firebase.firestore();
    
        db.collection("games")
            .doc(url)
            .set(
                {
                    time: timer - 1
                },
                { merge: true }
            );
    };

    const getTimeFromDataBase = () => {
    
        db.collection("games")
            .doc(url)
            .onSnapshot((snapshot) => {
                if (snapshot.data())
                    setTimer(snapshot.data().time);
            });
    };

    const changeFormat = () => {
        let minutes = Math.floor(timer / 60);
        let seconds = timer % 60
        let hours = Math.floor(minutes / 60)
        minutes = minutes % 60

        if (hours <= 9) {
            hours = '0' + hours
        }

        if (minutes <= 9) {
            minutes = '0' + minutes


        }

        if (seconds <= 9) {
            seconds = '0' + seconds
        }


        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);


    };

    console.log(url)
    console.log(timer)


       React.useEffect(() => {

    if(url){
        // cookies.set('userId', {uid:user.uid}, { path: '/' });
        getTimeFromDataBase();
        changeFormat();
    }

    

        // if (!userId) {
        //     db
        //         .collection(`games`)
        //         .doc(url)
        //         .get()
        //         .then((snapshot) => {
        //             // console.log(snapshot.data());
        //             setUserId(snapshot.data().uid);
        //         });
        // }

        if (isactive) {
            var interval = setInterval(() => {
                decreaseTimer();
                setTimer(timer - 1);
            }, 1000);
        }
        if (timer === 0) {
            setIsActive(false);
            clearInterval(interval);
            console.log("clear");
        }

        return () => clearInterval(interval);
    }, [timer, isactive, url]);

    return (
        <TimerContext.Provider value={{hours , seconds , minutes  , timer , setUrl , setIsActive , url}}>
            {props.children}
        </TimerContext.Provider>
        
    )
}


//    React.useEffect(() => {
    
//         getTimeFromDataBase(setTimer, url);
//         changeFormat(timer);
    

//         if (!userId) {
//             db
//                 .collection(`games`)
//                 .doc(url)
//                 .get()
//                 .then((snapshot) => {
//                     // console.log(snapshot.data());
//                     setUserId(snapshot.data().uid);
//                 });
//         }

//         if (isactive) {
//             var interval = setInterval(() => {
//                 decreaseTimer(timer, url);
//                 setTimer(timer - 1);
//             }, 1000);
//         }
//         if (timer === 0) {
//             setIsActive(false);
//             clearInterval(interval);
//             console.log("clear");
//         }

//         return () => clearInterval(interval);
//     }, [timer, isactive, url, userId, db]);
