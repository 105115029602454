import React, { useState, useRef } from 'react'
import { Button, Overlay, Popover } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile } from '@fortawesome/free-solid-svg-icons'



import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'


import alertify from 'alertifyjs'
import 'alertifyjs/build/css/alertify.css';

import firebase from '../firebase/firebase'


const ReactionalEmotes = ({ member, team, url }) => {

    const [selectedEmoji, setEmoji] = useState(null)
    const db = firebase.firestore()

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);


    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const addEmoji = (e) => {
        console.log(e)
        // setEmoji(e.native)
        const emote = e.native

        let collectionPath;
        let reactions
        if (team) {
            collectionPath = `games/${url}/teams/${team.id}/members`
        } else {
            collectionPath = `games/${url}/members`

        }
        if (member.reactions) {
            reactions = [...member.reactions, emote]

        } else {
            reactions = [emote]
        }

        db.collection(collectionPath)
            .doc(member.id)
            .set({
                reactions: reactions
            }, { merge: true })
        setShow(!show);



    }

    return (
        <div ref={ref}>
            <FontAwesomeIcon onClick={handleClick} icon={faSmile} />

            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref.current}
                containerPadding={20}
            >
                <Popover>
                    <Popover.Content>
                        <Picker onSelect={addEmoji} include={['people']} showPreview={false} title="" perLine={4} showSkinTones={false} />

                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    )
}

export default ReactionalEmotes
