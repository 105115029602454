import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/style.css";

import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import PageNotFound from "./pages/PageNotFound";
import DashBoard from "./pages/DashBoard";
import ScoreBoard from "./pages/ScoreBoard";
import BattleRoyale from './pages/BattleRoyale'

import SideBar from './components/SideBar'
import NavBar from './components/NavBar'


import ProtectedSignIn from "./protected.routes/signIn";
import ProtectedDashboard from "./protected.routes/dashboard";

import { PlayerProvider } from "./context/playerProvider";
import { UserProvider } from "./context/userProvider";
import {TimerProvider} from './context/timerProvider';



function App() {

    return (
        <UserProvider>
            <PlayerProvider>
                <TimerProvider>


                <div className="informationbody">
                    <Router>
                        <SideBar />
                        <div className="main">

                            <NavBar />


                            <Switch>


                                <ProtectedSignIn exact component={Login} path="/" />
                                <ProtectedSignIn exact component={SignUp} path="/signup" />




                                <ProtectedDashboard
                                    exact
                                    component={DashBoard}
                                    path="/dashboard"
                                />



                                <Route
                                    exact
                                    component={ScoreBoard}
                                    path="/scoreboard/:url"
                                />


                                <Route
                                    exact
                                    component={BattleRoyale}
                                    path="/battleroyale/:url"
                                />


                                <Route exact component={PageNotFound} path="/*" />
                            </Switch>

                        </div>
                    </Router>



                </div>
                </TimerProvider>
            </PlayerProvider>
        </UserProvider >
    );
}

export default App;
