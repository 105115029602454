import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const Legends = ({ legends }) => {

    if (legends) {

        return (
            <div className="mainbtnborder">
                <h3 class="activity">Metrices</h3>

                <div className="row">
                    <div className="col-md-12 mainbtnstyle">

                        <div className="row" >
                            {legends.map((legend, index) => (

                                <div className="col-md-6" key={legend.id}>
                                    <div className="mainbtn">
                                        {legend.name}&nbsp;(+{legend.points})
                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>
                </div>

            </div>

        )
    } else {
        return null
    }
}

export default Legends;