import React, { useState, useCallback, useContext } from "react";
import firebase from "../firebase/firebase";
import TeamBoard from "./TeamBoard";
import { remove, sortBy } from "lodash";
import GameTimer from "./Timer";
import { Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom"
import Legends from './Legends'
import TeamImages from './TeamImages'
import JoinPlayerTeam from './JoinPlayerTeam'
import { UserContext } from "../context/userProvider";



const GameBoard = ({ match }) => {
    const [teams, setTeams] = useState();
    const [legends, setLegends] = useState();
    const [gameName, SetGameName] = React.useState("");
    const [gameId, SetGameId] = React.useState("");



    const url = match.params.url


    const db = firebase.firestore();

    const { user } = React.useContext(UserContext);


    const getLegendsFromDB = useCallback(() => {
        let teamLegends = [];
        let unsubscribeLegends;
        unsubscribeLegends = db
            .collection(`games`)
            .doc(url)
            .collection("legends")
            .onSnapshot((snapshot) => {
                const changes = snapshot.docChanges();
                changes.forEach((change) => {
                    if (change.type === "added") {
                        const data = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        teamLegends.push(data);
                    } else if (change.type === "modified") {
                        remove(teamLegends, (o) => {
                            return o.id === change.doc.id;
                        });
                        const changeData = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        teamLegends.push(changeData);
                    } else if (change.type === "removed") {
                        remove(teamLegends, (o) => {
                            return o.id === change.doc.id;
                        });
                    }
                    teamLegends = sortBy(teamLegends, ["name"]);

                });
                setLegends(teamLegends);


            });

        return unsubscribeLegends;
    }, [db, url]);

    const getTeams = useCallback(() => {
        let teamData = [];


        const unsubscribe = db
            .collection(`games/${url}/teams/`)
            .onSnapshot((snapshot) => {
                let changes = snapshot.docChanges();
                changes.forEach((change) => {
                    if (change.type === "added") {
                        const data = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        teamData.push(data);
                    } else {
                        remove(teamData, (o) => {
                            return o.id === change.doc.id;
                        });
                        const changeData = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        teamData.push(changeData);
                    }
                });
                teamData = sortBy(teamData, ["headerName"]);
                setTeams([...teamData]);

            });

        return unsubscribe


    }, [db, url])

    const getGameName = useCallback(() => {

        db
            .collection(`games`)
            .doc(url)
            .onSnapshot((snapshot) => {
                if (snapshot.data()) {

                    SetGameName(snapshot.data().name);
                    SetGameId(snapshot.data().uid);
                }
            });
    }, [db, url])



    React.useEffect(() => {
        const unsubscribeTeam = getTeams()
        const unsubscribeLegends = getLegendsFromDB()
        getGameName()

        return () => {
            unsubscribeLegends()
            unsubscribeTeam()
        }


    }, [getTeams, getLegendsFromDB, getGameName]);

    const EndGame = () => {
        db.collection('games')
            .doc(url)
            .set({
                endGame: true
            }, { merge: true })
    }


    if (!teams) {
        return (
            
            <div className="container-fluid  spinnercenter">

            <Spinner class="spinnercenter" animation="border" variant="primary" />
            </div>

        )
        
    } else if (teams.length) {

        console.log(teams)
        return (

            <div className="container-fluid maindiv">
                {user && user !== 'waiting' && user.uid === gameId?
                    <div className="endGameRow">
                        <button className="playbtn endGame-btn" onClick={EndGame}>End Game</button>

                    </div>
                    :
                    <div className="endGameRow ">

                        <JoinPlayerTeam className="playbtn playbtnteam" url={url} legends={legends} teams={teams} gameId={gameId} />

                    </div>
                }
                <div className="row ">

                    <TeamBoard div='firstdiv' team={teams[0]} url={url} color="pink" legends={legends} memberLegendsColor={"#FF4794"} addbutton="playerbtnpink" gameId = {gameId} />

                    {/* <div className="col-md-4 col-sm-12 secdiv"> */}
                    <div class="col-lg-3 col-xl-4   col-sm-12 secdiv" >
                        <h3 className="Gamificationmatch">&nbsp;{gameName}  </h3>

                        <TeamImages teamA={teams[0]} teamB={teams[1]} />
                        <GameTimer url={url} gameId = {gameId}/>
                        <Legends legends={legends} />


                    </div>
                    <TeamBoard color='yellow' div='thirddiv' team={teams[1]} url={url} legends={legends} memberLegendsColor="#fa9917" addbutton="playerbtnyellow" gameId = {gameId}/>


                </div>
            </div>


        );
    } else {
     
     
        return <Redirect to={`/page-not-found`} />;
    }
};

export default GameBoard;
  // <div classNameName="container col-12  ">
            //     <div classNameName="mx-auto font-weight-bold text-lg-center" >
            //         <h2>{gameName}</h2>
            //     </div>
            //     <div classNameName="row ">
            //         <div classNameName="col-md-4">


            //             <TeamBoard team={teams[0]} url={url} color="pink" legends={legends} />
            //         </div>

            //         {/* Central Layout of GameBoard*/}

            //         <div classNameName="col-md-4 margin-top-setting">

            //             <JoinPlayer url={url} legends={legends} teams={teams} />

            //             {/* Layout for Team Images*/}

            //             <TeamImages teamA={teams[0]} teamB={teams[1]} color="yellow" />
            //             {/*  End */}


            //             {/* Layout Timer*/}

            //             <GameTimer url={url} />
            //             {/*  End */}

            //             {/*  LayOut For Legend */}

            //             <Legends legends={legends} />
            //             {/*  End */}



            //         </div>

            //         {/* Layout for Team2*/}
            //         <div classNameName="col-md-4">

            //             <TeamBoard team={teams[1]} url={url} color="yellow" legends={legends} />

            //         </div>
            //     </div>
            // </div>