import React, { useState, useEffect, useCallback, useContext } from 'react'
import firebase from '../firebase/firebase'
import { remove, set, sortBy, orderBy } from "lodash";
import TeamSummaryCard from './TeamSummaryCard'
import LegendsProgressSummary from './LegendsProgressSummary'
import { CSVLink } from "react-csv";
// import ReactToPdf from 'react-to-pdf'
// import html2pdf from 'html2pdf.js';
import summaryVersus from '../assets/images/summaryVersus.png'
// import { Navbar } from 'react-bootstrap';
import { UserContext } from '../context/userProvider'





const HeadToHeadSummary = ({ url }) => {
    const db = firebase.firestore()

    // const ref = React.createRef();
    const [teams, setTeams] = useState();
    const [csvData, setCsvData] = useState()
    const [teamAmembers, setTeamAMembers] = useState()
    const [teamBmembers, setTeamBMembers] = useState()
    const [eachLegendPointsTeamA, setEachLegendPointsTeamA] = useState()
    const [eachLegendPointsTeamB, setEachLegendPointsTeamB] = useState()
    const [gameCreatorId , setGameCreateorId] = useState()

    const { user } = useContext(UserContext)

    const [game, setGame] = useState()



    const [legends, setLegends] = useState()


    const getGame = useCallback(() => {
        db.collection(`games`)
            .doc(url)
            .get()
            .then(doc => {
                setGame(doc.data())
                setGameCreateorId(doc.data().uid)
            })


    }, [url, db])

    const getTeams = useCallback(() => {
        let teamData = [];


        const unsubscribe = db
            .collection(`games/${url}/teams/`)
            .onSnapshot((snapshot) => {
                let changes = snapshot.docChanges();
                changes.forEach((change) => {
                    if (change.type === "added") {
                        const data = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        teamData.push(data);
                    } else {
                        remove(teamData, (o) => {
                            return o.id === change.doc.id;
                        });
                        const changeData = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        teamData.push(changeData);
                    }
                });
                teamData = sortBy(teamData, ["headerName"]);
                setTeams([...teamData]);

            });

        return unsubscribe


    }, [db, url])

    const getLegendsFromDB = useCallback(() => {

        let battleRoyaleLegends = [];

        db.collection(`games/${url}/legends`)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let id = doc.id
                    battleRoyaleLegends.push({ id, ...doc.data() })
                })

                battleRoyaleLegends = sortBy(battleRoyaleLegends, ['id'])

                setLegends(battleRoyaleLegends)

            })


    }, [db, url])

    const getTeamAMembers = useCallback(() => {

        let members = [];
        if (teams && teams[0]) {


            db.collection(`games/${url}/teams/${teams[0].id}/members`)
                .where('playing', '==', true)
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let id = doc.id
                        members.push({ id, ...doc.data() })
                    })
                    members = orderBy(members, ['score'], ['desc'])
                    setTeamAMembers(members)
                })
        }



    }, [db, url, teams])

    const getTeamBMembers = useCallback(() => {

        let members = [];

        if (teams && teams[1]) {

            db.collection(`games/${url}/teams/${teams[1].id}/members`)
                .where('playing', '==', true)
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let id = doc.id
                        members.push({ id, ...doc.data() })
                    })
                    members = orderBy(members, ['score'], ['desc'])


                    setTeamBMembers(members)
                })
        }


    }, [db, url, teams])



    useEffect(() => {
        getTeamBMembers()
        getTeamAMembers()
        getGame()

    }, [getTeamBMembers, getTeamAMembers, getGame])




    useEffect(() => {
        getTeams()
        getLegendsFromDB()
    }, [getTeams, getLegendsFromDB])





    const generatePdf = () => {

        const download = window.document.getElementById('Download')
        const sidebar = window.document.getElementById('sidebar')
        const navbar = window.document.getElementById('navbar')

        download.style.display = 'none'

        if (sidebar) {
            sidebar.style.display = 'none'
            navbar.style.display = 'none'
        }


        window.print();


        download.style.display = 'block'


        if (sidebar) {

            navbar.style.display = 'block'
            sidebar.style.display = 'block'
        }



    }

    const memberLegendsOccurance = useCallback((members) => {
        let totalScoreOfLegends = []

        members.forEach(member => {

            legends.forEach(legend => {
                totalScoreOfLegends.push({ ...legend, totalOccurance: 0 })

            });

            if (member.legends) {

                member.legends.forEach(legend => {
                    let updatedLegend = remove(totalScoreOfLegends, (o) => { return o.id === legend.legendId })
                    if (updatedLegend[0]) {
                        updatedLegend[0].totalOccurance += legend.frequency
                        totalScoreOfLegends.push(updatedLegend[0])
                    }



                })
            }



        });

        return totalScoreOfLegends;

    }, [legends])

    useEffect(() => {
        if (legends && teamAmembers && teamBmembers) {

            const totalScoreOfLegendsTeamA = memberLegendsOccurance(teamAmembers)
            const totalScoreOfLegendsTeamB = memberLegendsOccurance(teamBmembers)

            setEachLegendPointsTeamA(sortBy(totalScoreOfLegendsTeamA, ['id']))
            setEachLegendPointsTeamB(sortBy(totalScoreOfLegendsTeamB, ['id']))

        }

    }, [legends, teamAmembers, teamBmembers, memberLegendsOccurance])

    useEffect(() => {

        if (legends && teamAmembers && teamBmembers && eachLegendPointsTeamA && eachLegendPointsTeamB) {

            const csvRow = []
            const header = ['Players', 'Email', 'Team', 'Total Points']
            legends.forEach(legend => {
                header.push(legend.name)
            })

            csvRow.push(header)

            teamAmembers.forEach(member => {
                let row = []
                row.push(member.name)
                row.push(member.email)
                row.push(teams[0].name)
                row.push(member.score)

                sortBy(member.legends, ['legendId']).forEach(legend => {
                    row.push(legend.frequency)
                })

                csvRow.push(row)

            })

            teamBmembers.forEach(member => {
                let row = []
                row.push(member.name)
                row.push(member.email)
                row.push(teams[1].name)
                row.push(member.score)

                sortBy(member.legends, ['legendId']).forEach(legend => {
                    row.push(legend.frequency)
                })

                csvRow.push(row)


            })



            setCsvData(csvRow)


        }


    }, [teamAmembers, teamBmembers, legends, teams, eachLegendPointsTeamA, eachLegendPointsTeamB])


    if (teams) {

        return (
            <><div className="card ">
                <div id='headtoheadSummary'>
                    <div class="row container-fluid">
                        <div class="">
                        </div>
                        <TeamSummaryCard color='pink' image='' team={teams[0]} url={url} legends={legends} members={teamAmembers} user ={user}/>
                        <div class="col-md-12 col-lg-2 col-xl-2 col-sm-12 ">
                            <div class="summaryvs">
                                <h3 class="gamesummaryH">
                                    GAME SUMMARY
                    </h3>
                                <img src={summaryVersus} className="vsimg" alt="" />
                            </div>
                        </div>
                        <TeamSummaryCard color='yellow' image='2' team={teams[1]} url={url} legends={legends} members={teamBmembers} user ={user}/>
                    </div>
                    <div className='row container-fluid'>
                        <div class="">
                        </div>
                        <LegendsProgressSummary color='pink' eachLegendPoints={eachLegendPointsTeamA} team={teams[0]} progress="" />
                        <div class="col-md-12 col-lg-2 col-xl-2 col-sm-12 ">
                            <div class="gamepointcenter">
                                {legends ?
                                    legends.map(legend => (
                                        <p key={legend.id} class="summaryPoints">{legend.name} total</p>
                                    ))
                                    : null}
                            </div>
                        </div>
                        <LegendsProgressSummary color='yellow' eachLegendPoints={eachLegendPointsTeamB} team={teams[1]} progress="progressinverse" />
                        <div class="donwloadtext" id="Download">
                            <text class="donwloadtextas">DOWNLOAD RESULT AS</text>
                            <button class="btn_download" onClick={generatePdf}>PDF</button>
                            {csvData && user && user.uid === gameCreatorId ?
                                <button className="btn_download">
                                    <CSVLink data={csvData} filename={game ? `${game.name}.csv` : 'battleRoyale.csv'}> CSV </CSVLink>
                                </button>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
            </>


        )
    } else {
        return null
    }
}

export default HeadToHeadSummary


/* <div id='headtoheadSummary'>
                    <div class="row container-fluid">

                        <div class="col-md-1">

                        </div>
                        <TeamSummaryCard color='pink' image='' team={teams[0]} url={url} legends={legends} members={teamAmembers} />
                        <div class="col-md-2">
                            <div class="summaryvs">

                                <h3 class="gamesummaryH">
                                    GAME SUMMARY
                        </h3>

                                <img src={summaryVersus} className="vsimg" alt="" />

                            </div>
                        </div>
                        <TeamSummaryCard color='yellow' image='2' team={teams[1]} url={url} legends={legends} members={teamBmembers} />

                    </div>

                    <div className='row container-fluid'>
                        <div class="col-md-1">

                        </div>
                        <LegendsProgressSummary color='pink' members={teamAmembers} legends={legends} team={teams[0]} progress="" />

                        <div class="col-md-2">
                            <div class="gamepointcenter">
                                {legends ?
                                    legends.map(legend => (
                                        <p key={legend.id} class="summaryPoints">{legend.name} Per Game</p>


                                    ))
                                    : null}


                            </div>
                        </div>
                        <LegendsProgressSummary color='yellow' members={teamBmembers} legends={legends} team={teams[1]} progress="progressinverse" />




                    </div>
                </div>


                <div class="donwloadtext" id="Download">
                    <text class="donwloadtextas">DOWNLOAD RESULT AS</text>

                    <button class="btn_download" onClick={generatePdf}>PDF</button>

                    {csvData ?
                        <button className="btn_download">
                            <CSVLink data={csvData} filename={game ? `${game.name}.csv` : 'battleRoyale.csv'}> CSV </CSVLink>
                        </button>
                        : null}
                    {/* <button class="btn_download">
                        CSV
                              </button> */
                            //   </div>