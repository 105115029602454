import React, { useState, useCallback } from "react";
import firebase from "../firebase/firebase";
import { remove, sortBy, orderBy, find } from "lodash";
import GameTimer from "./Timer";
import Legends from './Legends'
import BattleRoyaleBoard from './BattleRoyaleBoard'
import JoinPlayerBattle from './JoinPlayerBattle'
import AddBattlePlayerModal from "./AddBattlePlayerModal.js";
import { Spinner } from "react-bootstrap";

import { PlayerContext } from '../context/playerProvider'
import { UserContext } from "../context/userProvider";


import alertify from 'alertifyjs'
import 'alertifyjs/build/css/alertify.css';




const BattleGameBoard = ({ match }) => {

    const [gameName, SetGameName] = React.useState("");
    const [members, setMembers] = useState();
    const [removedMember, setRemovedMember] = useState()
    const [legends, setLegends] = useState()
    const [gameId , setGameId] = useState()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const url = match.params.url


    const db = firebase.firestore();
    const { cookies, setCookiesChanged, cookiesChanged } = React.useContext(PlayerContext)


    const { user } = React.useContext(UserContext);


    const getMembersFromDB = useCallback(() => {
        let teamMembers = [];
        let unsubscribe;
        unsubscribe = db
            .collection(`games/${url}/members`)
            .where('playing', '==', true)
            .onSnapshot((snapshot) => {
                const changes = snapshot.docChanges();
                changes.forEach((change) => {
                    if (change.type === "added") {
                        const data = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        teamMembers.push(data);
                    } else if (change.type === "modified") {
                        const membersPreviousState = remove(teamMembers, (o) => {
                            return o.id === change.doc.id;
                        });

                        const changeData = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        // alertGifies(changeData, membersPreviousState[0])
                        showAlertOfEmote(changeData, membersPreviousState[0])

                        teamMembers.push(changeData);
                    } else if (change.type === "removed") {
                        const removedMember = remove(teamMembers, (o) => {
                            return o.id === change.doc.id;
                        });

                        setRemovedMember(removedMember[0])

                    }
                    teamMembers = orderBy(teamMembers, ["score"], ['desc']);

                });

                setMembers(teamMembers);



            });

        return unsubscribe;
    }, [url, db]);

    const showAlertOfEmote = (changedData, membersPreviousState) => {

        const reactions = changedData.reactions
        const previousReactions = membersPreviousState.reactions

        let emoji;

        if (reactions) {
            if (previousReactions) {

                if (reactions.length > previousReactions.length) {
                    emoji = reactions[reactions.length - 1]
                    alertify.message(`Admin Gave ${emoji} to ${changedData.name}`)



                }

            } else {
                emoji = reactions[0]
                alertify.message(`Admin Gave ${emoji} to ${changedData.name}`)

            }



        }

    }

    const alertGifies = (changeData, membersPreviousState) => {

        const gifies = changeData.gifies
        console.log(gifies)
        const previousgifies = membersPreviousState.gifies

        if (previousgifies) {
            const lastModifiedGify = gifies[gifies.length - 1]

            const previousStateOfGify = find(previousgifies, (o) => { return o.id === lastModifiedGify.id })
            if (previousStateOfGify) {
                if (lastModifiedGify.frequency > previousStateOfGify.frequency) {

                    let gifyToShow = `admin sent <img src=${lastModifiedGify.url} width=150 height=150 /> to ${changeData.name}`
                    alertify.notify(gifyToShow)
                }

            } else {
                let gifyToShow = `admin sent <img src=${lastModifiedGify.url} width=150 height=150 /> to ${changeData.name}`
                alertify.notify(gifyToShow)
            }

        } else {
            if (gifies) {

                let gify = gifies[gifies.length - 1]
                let gifyToShow = `Admin gave <img src=${gify.url} width=150 height=150 /> to ${changeData.name}`
                alertify.notify(gifyToShow)
            }

        }

    }

    const RemovedByAdmin = React.useCallback(() => {

        const player = cookies.get('battleplayer')

        if (player) {
            db.collection(`games/${url}/members`)
                .doc(player.id)
                .get()
                .then(doc => {
                    if (doc.data().playing === false) {
                        cookies.remove('battleplayer', { path: '/' })
                        setCookiesChanged(!cookiesChanged)

                    }
                })
        }

    }, [removedMember, setCookiesChanged, cookiesChanged, cookies])

    const getLegendsFromDB = useCallback(() => {
        let teamLegends = [];
        let unsubscribeLegends;
        unsubscribeLegends = db
            .collection(`games`)
            .doc(url)
            .collection("legends")
            .onSnapshot((snapshot) => {
                const changes = snapshot.docChanges();
                changes.forEach((change) => {
                    if (change.type === "added") {
                        const data = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };
                        teamLegends.push(data);
                    } else if (change.type === "modified") {
                        remove(teamLegends, (o) => {
                            return o.id === change.doc.id;
                        });
                        const changeData = {
                            ...change.doc.data(),
                            id: change.doc.id
                        };


                        teamLegends.push(changeData);
                    } else if (change.type === "removed") {
                        remove(teamLegends, (o) => {
                            return o.id === change.doc.id;
                        });
                    }
                    teamLegends = sortBy(teamLegends, ["name"]);
                });

                setLegends(teamLegends);

            })

        return unsubscribeLegends;
    }, [db, url, setLegends]);

    const getGameName = useCallback(() => {

        db
            .collection(`games`)
            .doc(url)
            .onSnapshot((snapshot) => {
                if (snapshot.data()) {

                    SetGameName(snapshot.data().name);
                    setGameId(snapshot.data().uid)
                }
            });
    }, [db, url])

    const EndGame = () => {
        db.collection('games')
            .doc(url)
            .set({
                endGame: true
            }, { merge: true })
    }



    React.useEffect(() => {
        const unsubscribeLegends = getLegendsFromDB()
        const unsubscribeMembers = getMembersFromDB()
        getGameName()

        return () => {
            unsubscribeLegends()
            unsubscribeMembers()
        }


    }, [getLegendsFromDB, getGameName, getMembersFromDB]);


    React.useEffect(() => {
        RemovedByAdmin()

    }, [RemovedByAdmin])




    if (members) {


        return (

            <div className="container-fluid maindiv">
                {user && user !== 'waiting' && user.uid === gameId ?
                    <div className="endGameRow">
                        <button className="playbtn endGame-btn" onClick={EndGame}>End Game</button>
                        <button type="button" className={`playbtn playerbtnred`} onClick={handleShow}>Add&nbsp;Player</button>
                        <AddBattlePlayerModal closePlayerModal={handleClose} legends={legends} url={url} color="red" show={show} members={members} />
                    </div>

                    :
                    <div className="endGameRow">

                        <JoinPlayerBattle url={url} legends={legends} members={members} gameId = {gameId} />

                    </div>
                }
                <div className="row">

                    <BattleRoyaleBoard div='firstdiv' color='pink' url={url} legends={legends} memberLegendsColor={"#CE3535"} members={members.slice(0, 10)} gameId = {gameId}/>


                    <div class="col-lg-4 col-xl-4  col-sm-12 thirddiv" >
                        <h3 className="Gamificationmatch">&nbsp;{gameName}  </h3>

                        <GameTimer url={url} gameId = {gameId}/>
                        <Legends legends={legends} />


                    </div>
                    <BattleRoyaleBoard color='yellow' div='thirddiv' url={url} legends={legends} memberLegendsColor={"#CE3535"} members={members.slice(10, 20)} second = {true} gameId = {gameId}/>


                </div>
            </div>





        );
    } else {
        
        return (
            
            <div className="container-fluid  spinnercenter">

            <Spinner class="spinnercenter" animation="border" variant="primary" />
            </div>

        )
    }


};

export default BattleGameBoard;


//   <div className="container-fluid maindiv">
//             <div className="row">

//                 {/* <div class="row"> */}

//                 <div className="col-md-1  emptydiv ">

//                 </div>

//                 <div className="col-md-4  firstdiv">
//                     <h3 className="Gamificationmatch">&nbsp;{gameName}  </h3>

//                     <GameTimer url={url} />
//                     <Legends legends={legends} />

//                 </div>

//                 <BattleRoyaleBoard color='red' url={url} legends={legends} memberLegendsColor={"#CE3535"} members={members} />



//                 {/* </div> */}
//             </div>



//         </div>