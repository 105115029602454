import React from 'react'
import { Modal, ModalBody } from "reactstrap";
import firebase from '../firebase/firebase'
import { toUpper, toLower } from 'lodash'
import { PlayerContext } from '../context/playerProvider'
import { UserContext } from '../context/userProvider'




const AddTeamPlayerModal = ({ team, closePlayerModal, openedPlayerModal, legends, url, color, members }) => {

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const emailInput = React.useRef()


    const { cookies, setCookiesChanged } = React.useContext(PlayerContext)
    const { user } = React.useContext(UserContext)


    const db = firebase.firestore()

    const addPlayer = () => {
        console.log(email)
        if (name && email) {
            console.log(members.length)
            if (validateEmail(email)) {
                emailInput.current.style.borderColor = "black"

                if (members.length < 10) {

                    db.collectionGroup(`members`)
                        .where("gameId", '==', url)
                        .orderBy("email")
                        .startAt(toUpper(email))
                        .endAt(toLower(email) + "\uf8ff")
                        .get()
                        .then((snapshot) => {
                            let record = false;
                            snapshot.docs.forEach((doc) => {
                                if (toLower(doc.data().email) === toLower(email)) {
                                    let id = doc.id
                                    let ref = doc.ref
                                    record = { ...doc.data(), id, ref };
                                }
                            });


                            if (record) {
                                if (record.playing === true) {
                                    alert("Player already exists")
                                } else {

                                    const email = record.email
                                    record.ref.delete()


                                    const legendObjects = legends.map(legend => {
                                        const legendId = legend.id
                                        const frequency = 0
                                        return { legendId, frequency }
                                    })

                                    db.collection(`games/${url}/teams/${team.id}/members`)
                                        .add({
                                            name: name,
                                            email: email,
                                            gameId: url,
                                            type: 'added',
                                            playing: true,
                                            score: 0,
                                            legends: [...legendObjects]
                                        })

                                    // db.collection(`games/${url}/teams/${team.id}/members`)
                                    //     .doc(record.id)
                                    //     .set({
                                    //         playing: true,
                                    //     }, { merge: true })


                                }

                            } else {
                                const legendObjects = legends.map(legend => {
                                    const legendId = legend.id
                                    const frequency = 0
                                    return { legendId, frequency }
                                })

                                db.collection(`games/${url}/teams/${team.id}/members`)
                                    .doc()
                                    .set(
                                        {
                                            name: name,
                                            email: email,
                                            gameId: url,
                                            type: 'added',
                                            playing: true,
                                            score: 0,
                                            legends: [...legendObjects]
                                        },
                                        { merge: true }
                                    ).then(() => {
                                        setEmail('')
                                        setName('')

                                    })
                                if (!user) {
                                    cookies.set('player', { name: name, teamId: team.id }, { path: '/' });
                                    setCookiesChanged(false)

                                }



                            }
                            closePlayerModal()
                            setEmail('')
                            setName('')
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    alert("Team is Full")
                }
            } else {
                emailInput.current.style.borderColor = 'red'
                emailInput.current.value = email
                setEmail('invalid')
            }

        } else {
            alert("Enter Name and Email");
        }
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

if(team){

    return (
        <Modal
            isOpen={openedPlayerModal === team.id}
            toggle={closePlayerModal}

        >
            <ModalBody>
                <div id="modalCenter" className="modal-block mfp-hide">
                    <section className="panel">
                        <div className={`panel-body card matchcard cardborder${color}`}>
                            <div className="modal-wrapper p-4">
                                <div className="modal-text text-left    ">
                                    <h4 style={{ fontWeight: 'bold' }}>Enter Player Detail</h4>


                                    <input type="text" placeholder="Enter player Name" maxLength="30" className="Addmodelinput1" onChange={(e) => { setName(e.target.value) }}
                                    />


                                    <input type="text" ref={emailInput} placeholder="Player Email" className="Addmodelinput2" onChange={(e) => { setEmail(e.target.value) }} />
                                    {email === 'invalid'? 
                                    <p style={{fontSize:12 , color:'red'}}>Email is invalid!</p>
                                    :null}

                                    <button className={`btn btn-default modelYesbtn btn${color}yes`} onClick={() => { addPlayer() }}>Add</button>
                                    <button className="btn btn-default modelNobtn" onClick={closePlayerModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </ModalBody>

        </Modal >
    )
}else{
    return (
        null
    )
}
}


export default AddTeamPlayerModal
