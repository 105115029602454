import React from 'react'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from 'react-bootstrap'
import firebase from '../firebase/firebase'
import { find, remove } from 'lodash'


const UpdateLegendModal = ({ legends, member, closeModal, openedModal, url, team, color }) => {

    const db = firebase.firestore()


    const removeLegendfromMember = (e, memberlegends) => {

        let legend = find(memberlegends, o => o.legendId === e.target.id)

        let newLegend = find(legends, o => o.id === e.target.id)


        let collectionPath;
        console.log(newLegend)
        if (legend && legend.frequency > 0) {
            remove(memberlegends, o => o.legendId === e.target.id)
            const legendId = legend.legendId
            const frequency = legend.frequency - 1

            const totalScore = member.score - newLegend.points

            console.log(totalScore)
            legend = { legendId, frequency }

            const verifyLegend = [...memberlegends, legend]

            if (team) {
                collectionPath = `games/${url}/teams/${team.id}/members`
            } else {
                collectionPath = `games/${url}/members`


            }

            db.collection(collectionPath)
                .doc(member.id)
                .set(
                    {
                        score: totalScore,
                        legends: [...verifyLegend]
                    },
                    { merge: true }
                );


            if (team) {
                const teamScore = team.score - newLegend.points

                db.collection(`games/${url}/teams`)
                    .doc(team.id)
                    .set(
                        {
                            score: teamScore
                        },
                        { merge: true }
                    );
            }


        }


    };

    const addLegendInMember = (e, memberLegends) => {

        let legend = find(memberLegends, o => o.legendId === e.target.id)
        let newLegend = find(legends, o => o.id === e.target.id)


        let collectionPath;

        if (team) {
            collectionPath = `games/${url}/teams/${team.id}/members`
        } else {
            collectionPath = `games/${url}/members`


        }

        if (legend) {
            remove(memberLegends, o => o.legendId === e.target.id)
            const legendId = legend.legendId
            const frequency = legend.frequency + 1
            const totalScore = member.score + newLegend.points
            legend = { legendId, frequency }

            const verifyLegend = [...memberLegends, legend]

            db.collection(collectionPath)
                .doc(member.id)
                .set(
                    {
                        score: totalScore,
                        legends: [...verifyLegend]
                    },
                    { merge: true }
                );

        }

        if (team) {
            db.collection(`games/${url}/teams`)
                .doc(team.id)
                .set(
                    {
                        score: team.score + newLegend.points
                    },
                    { merge: true }
                );
        }


    };

    const findOccurance = (memberLegends, id) => {

        const legend = find(memberLegends, o => o.legendId === id)
        if (legend) {
            return legend.frequency

        }

        return 0
    }

    return (
        <Modal
            isOpen={openedModal === member.id}
            toggle={closeModal}
            style={{ backgroundColor: '#ffff' }}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <svg width="1em"
                    onClick={closeModal}

                    height="1em" viewBox="0 0 16 16" className=" crossimage bi bi-x-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                </svg>

            </div>

            <ModalHeader >
                <p className="scoreHeading"> {member.name}'s Score</p>


            </ModalHeader>

            <ModalBody style={{
                border: '1px solid rgba(0, 0, 0, 0.2)',
                backgroundColor: '#ffff',
                borderRadius: '0.3rem'
            }}>
                <table className="tablewidth">

                    <tbody>

                        {legends
                            ? legends.map(
                                ({ name, points, id }) => (
                                    <tr key={id}>
                                        <td>
                                            {name}

                                        </td>
                                        <td>
                                            <Button
                                                className="btnmargin"
                                                id={id}
                                                variant='info'
                                                size='sm'
                                                onClick={(e) => {
                                                    removeLegendfromMember(
                                                        e,
                                                        member.legends
                                                    );
                                                }}
                                            >
                                                -{points}
                                            </Button>
                                        </td>

                                        <td>
                                            <label style={{ padding: '.25rem' }}>
                                                {findOccurance(member.legends, id) * points}
                                            </label>
                                        </td>

                                        <td>
                                            <Button
                                                variant='default'

                                                className={`btnmargin  scoreModelBtn${color}`}
                                                id={id}
                                                onClick={(e) => {
                                                    addLegendInMember(
                                                        e,
                                                        member.legends
                                                    );
                                                }} >
                                                +{points}
                                            </Button>
                                        </td>


                                    </tr>
                                )
                            )
                            : null}
                    </tbody>
                </table>


            </ModalBody>

        </Modal >
    )
}

export default UpdateLegendModal
