import React, { useState, useEffect } from "react";
import GameBoard from "../components/GameBoard";
import { UserContext } from "../context/userProvider";
import { Spinner } from "react-bootstrap";
import firebase from '../firebase/firebase'
import {TimerContext} from '../context/timerProvider'
import HeadToHeadSummary from '../components/HeadToHeadSummary'

const ScoreBoard = (props) => {
    const { user } = React.useContext(UserContext);
    const { setUrl } = React.useContext(TimerContext);


    const db = firebase.firestore()
    const url = props.match.params.url

    const [endGame, setEndgame] = useState('waiting')

    useEffect(() => {

        const unsubscribe = db.collection('games').doc(url)
            .onSnapshot(snapshot => {
                if (snapshot.data()) {
                    setEndgame(snapshot.data().endGame)


                }
            })


        return unsubscribe


    }, [endGame, db, url])

    useEffect(() =>{
        setUrl(url)
    },[url])



    if (user === "waiting") {
        return (
            <Spinner animation="grow" variant="primary" />
        )
    } else if (endGame !== 'waiting') {
        if (endGame) {

            return (
                <HeadToHeadSummary url={url} />
            )

        }
        else {
            return (

                <GameBoard match={props.match} />


            );




        }

    } else {
        return (
            null
        )
    }
};

export default ScoreBoard;

