import React, { useState, useEffect } from "react";
import BattleGameBoard from "../components/BattleGameBoard";
import BattleRoyaleSummary from '../components/battleRoyaleSummary'
import { UserContext } from "../context/userProvider";
import { Spinner } from "react-bootstrap";
import firebase from '../firebase/firebase'
import Cookies from 'universal-cookie'
import {TimerContext} from '../context/timerProvider'


const BattleRoyale = (props) => {

    const db = firebase.firestore()
    const url = props.match.params.url
    const cookies = new Cookies()
    const { setUrl } = React.useContext(TimerContext);

    const [endGame, setEndgame] = useState('waiting')

    useEffect(() => {

        const unsubscribe = db.collection('games').doc(url)
            .onSnapshot(snapshot => {
                if (snapshot.data()) {
                    setEndgame(snapshot.data().endGame)


                }
            })


        return unsubscribe


    }, [endGame, db, url])

    useEffect(() =>{
        setUrl(url)
    },[url])

    // console.log(props.match.params.url)
    const { user } = React.useContext(UserContext);



    if (user === "waiting") {
        return (
            <Spinner animation="grow" variant="primary" />
        )
    } else if (endGame !== 'waiting') {
        if (endGame) {
            return (
                <BattleRoyaleSummary url={url} />
            )



        }
        else {


            return (

                <BattleGameBoard match={props.match} />

            );


        }

    } else {
        return (
            null
        )
    }

};

export default BattleRoyale;
