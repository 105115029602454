import React, { useState } from 'react'
import { PlayerContext } from '../context/playerProvider'
import firebase from "../firebase/firebase";
import JoinPlayerModal from './JoinPlayerModal'
import { UserContext } from '../context/userProvider'



const JoinPlayerTeam = ({ teams, url, legends , gameId }) => {

    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const { cookies, cookiesChanged, setCookiesChanged } = React.useContext(PlayerContext)
    const { user } = React.useContext(UserContext)


    const db = firebase.firestore()

    const LeaveMember = () => {
        let player = cookies.get('teamplayer')
        if (player) {
            const memberLegends = legends.map(legend => {
                return { id: legend.id, frequency: 0 }
            })

            db.collection(`games/${url}/teams/${player.teamId}/members`)
                .doc(player.id)
                .set({
                    playing: false
                }, { merge: true }).then(() => {
                    cookies.remove('teamplayer', { path: '/' })
                    setCookiesChanged(!cookiesChanged)
                })



        }

    }

    const JoinAndLeaveCondition = () => {


        if (cookies.get('teamplayer')) {
            const player = cookies.get('teamplayer')
            return (
                <div className="row">
                    <div className="col-md-12">
                        <div className="media">
                            <div class="avatarbag">
                                <img src={player.avatar} alt="Avatar" class="avatarjoin align-self-start " />
                            </div>
                            <div class="media-body">
                                <div class="joinnamediv">
                                    <h3 class="mt-0 medianam">{player.name}</h3>
                                    <p class="emailjointext">{player.email}</p>
                                    <button class="redbtn" onClick={LeaveMember}>Leave Game</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )


        } else {
            return (
                <div className="text-center gamification-right-button">
                    <button type="button" className="joinplaybtn" onClick={handleShow}>Join</button>
                    <JoinPlayerModal teams={teams} closePlayerModal={handleClose} legends={legends} url={url} show={show} />
                </div>
            )
        }

    }


    if (!user || user.uid !== gameId) {

        return (
            JoinAndLeaveCondition()
        )
    } else {
        return null
    }
}

export default JoinPlayerTeam
