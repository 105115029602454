import React from 'react'
import Versus from '../assets/images/CombinedShape.png'
import Avatar from '../assets/images/lion.png'


const TeamImages = ({ teamA, teamB }) => {

    if (teamA && teamB) {
        return (
            <div className="row wolfdiv">

                <div className="col-md-5">
                    <img className="imggame" src={teamA.image ? teamA.image : Avatar} alt=""  width="80" />
                    <h4 className="Teamscore"> {teamA.name !== '' ? teamA.name : teamA.headerName}</h4>
                    <div className="team2-score">{teamA.score}</div>
                </div>
                <div className="col-md-2">
                    <img className="imggame" src={Versus} alt="" />
                </div>
                <div className="col-md-5">
                    <img className="imggame" src={teamB.image ? teamB.image : Avatar} alt=""  width="80" />
                    <h4 className="Teamscore"> {teamB.name !== '' ? teamB.name : teamB.headerName} </h4>
                    <div className="team1-score">{teamB.score}</div>
                </div>


            </div>


        )
    } else {
        return null
    }
}

export default TeamImages

/* // <div classNameName="row top-space-button">

//     <div classNameName="col-sm ">
//         <img src={teamA.image ? teamA.image : Avatar} alt='teamImage2' height={60} width={70} />
//         <h5 style={{ fontWeight: "bold" }}>{teamA.name !== '' ? teamA.name : teamA.headerName}'s Score</h5>
//         <div classNameName="well warnings">
//             {teamA.score}
//         </div>

//     </div>
//     <div classNameName="col-sm">
//         <img src={Versus} alt='teamImage' />


//     </div>
//     <div classNameName="col-sm">
//         <img src={teamB.image ? teamB.image : Avatar} alt='teamImage2' height={60} width={70} />
//         <h5 style={{ fontWeight: "bold" }}>{teamB.name !== '' ? teamB.name : teamB.headerName}'s Score</h5>
//         <div classNameName="well warning">
//             {teamB.score}

//         </div>
//     </div>
// </div> */