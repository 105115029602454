import React from "react";
import Cookies from 'universal-cookie'

export const PlayerContext = React.createContext();

export const PlayerProvider = (props) => {


    const cookies = new Cookies();

    const [cookiesChanged, setCookiesChanged] = React.useState(false)


    return (
        <PlayerContext.Provider value={{ cookies: cookies, setCookiesChanged: setCookiesChanged, cookiesChanged: cookiesChanged }}>
            {props.children}
        </PlayerContext.Provider>
    );
};
