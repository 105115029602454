import React, { useState } from 'react'
import firebase from '../firebase/firebase'
import { find, toInteger } from 'lodash'
import { UserContext } from '../context/userProvider'
import UpdateLegendsModal from './UpdateLegendsModal'
import RemoveMemberModal from './RemoveMemberModal'
import ReactionalEmotes from './ReactionalEmotes'
import Baba from '../assets/images/baba.png'
import Gify from './Gify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCircle } from '@fortawesome/free-solid-svg-icons'


const Member = ({ url, member, legends, team, members, number, color, memberLegendsColor , gameId }) => {

    const [openedModal, setModal] = useState(true);
    const [openedDeleteModal, setOpenedDeleteModal] = useState(false)

    const { user } = React.useContext(UserContext);


    const db = firebase.firestore()

    const openModal = (id) => {
        setModal(id);
    };

    const closeModal = () => {
        setModal(null);
    };

    const openDeleteModal = (id) => {
        setOpenedDeleteModal(id);
    };

    const closeDeleteModal = () => {
        setOpenedDeleteModal(null);
    };

    const foundLegendFromId = (legend) => {
        if (legends && legends.length && legend.legendId) {
            const legendFound = find(legends, (o) => {
                return o.id === legend.legendId;
            });
            if (legendFound && legend.frequency > 0) {
                const element = (
                    <span
                        key={legend.legendId}
                    >
                        <FontAwesomeIcon icon={faCircle} color={memberLegendsColor} />&nbsp;&nbsp;
                        {legendFound.name}&nbsp;
    (+{legendFound.points})
                        <br />
                    </span>

                );

                return element;
            }
        }
    };

    const removeMember = (e) => {
        let collectionPath;
        if (team) {
            collectionPath = `games/${url}/teams/${team.id}/members`
        } else {
            collectionPath = `games/${url}/members`

        }

        // console.log(legends)
        // const memberLegends = legends.map(legend => {
        //     return { id: legend.id, frequency: 0 }
        // })

        // for(let i = 0 ; i < legends.length ; i++){

        // }
        // legends.foreach(legend => {
        //     memberLegends.push({ id: legend.id, frequency: 0 })
        // })

        db.collection(collectionPath)
            .doc(e.target.id)
            .set({
                legends:[],
                playing: false

            }, { merge: true })

        if (team) {
            const updatedScore = toInteger(team.score) - toInteger(member.score)
            db.collection(`games/${url}/teams`).doc(team.id).set(
                {
                    score: updatedScore
                },
                { merge: true }
            );
        }


    };






    return (
        < tr >
            <td className="membernum">{number}</td>
            <div class="tooltip">   <td>

                <strong>{member.name}</strong>

                <span class="tooltiptext">
                    {member.legends
                        ? member.legends.map((legend) => {
                            return foundLegendFromId(
                                legend
                            )
                        })
                        : null}
                </span>
                {/* {user && user !== 'waiting' ?
                    <div>
                        <span className="memberemail" style={{ color: "gray", fontSize: "9px" }}>
                            {member.email}
                        </span>
                    </div>
                    : null} */}
            </td>
            </div>

            <td>
                <span className={`numbercolor-${color}`}>{member.score}</span>
            </td>
            <td>
                <img className="avtarmember" src={member.avatar ? member.avatar : Baba} alt="avatar" />

            </td>
            {user && user.uid === gameId?
                <>

                    <td>
                        <button
                            className="updatebtn"
                            onClick={() => {
                                openModal(member.id);
                            }}
                        > Update
                    {/* &nbsp;score */}
                        </button>
                    </td>
                    <td>
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={() => { openDeleteModal(member.id) }}

                        />
                    </td>

                    <td>
                        {/* <Gify member={member} team={team} url={url} /> */}
                        <ReactionalEmotes member={member} team={team} url={url} />
                    </td>

                </>
                :
                null}

            <UpdateLegendsModal legends={legends} member={member} closeModal={closeModal} openedModal={openedModal} url={url} team={team} color={color} />
            <RemoveMemberModal member={member} closeDeleteModal={closeDeleteModal} openedDeleteModal={openedDeleteModal} removeMember={removeMember} color={color} />

        </tr>


    )
}

export default Member
