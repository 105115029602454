import React from "react";
import firebase from "firebase";

export const UserContext = React.createContext();

export const UserProvider = (props) => {
    const [user, setUser] = React.useState("waiting");
    const [saveClick, setSaveClick] = React.useState(false)

    React.useEffect(() => {
        const unsubscribe = authlistner();
        return unsubscribe
    }, []);




    const authlistner = () => {
        const auth = firebase.auth();
        const unsubscribe = auth.onAuthStateChanged(user => {
            const data = user;
            setUser(data);


        });

        return unsubscribe;
    };

    return (
        <UserContext.Provider value={{ user: user, saveClick, setSaveClick }}>
            {props.children}
        </UserContext.Provider>
    );
};
