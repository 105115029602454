import React, { useState, useCallback, useEffect } from "react";
import Member from './Member'
import firebase from "../firebase/firebase";
import { find, remove } from "lodash";


import { UserContext } from '../context/userProvider'


const BattleRoyaleBoard = (props) => {
    const { url, color, legends, memberLegendsColor, members, div , second , gameId  } = props;

    // const { user } = React.useContext(UserContext);
    const [legendsLength, setLegendsLength] = React.useState(0)
    const [membersUpdated , setMembersUpdated]  = useState(false)



    const db = firebase.firestore();


    const calculateLegendsLengthInDb = useCallback((legends) => {
        db.collection(`games/${url}/legends`)
            .get()
            .then(snapshot => {
                setLegendsLength(snapshot.docs.length)
            })
    },[db,url])

    // const calculateMemberScore = useCallback(() => {

    //     if (members && legends && members.length && legends !== 'waiting') {
    //         members.forEach((member) => {
    //             // const updatedLegends = []
    //             // const legendsCopy = [...legends]


    //             let membersCalculatedScore = 0;
    //             calculateLegendsLengthInDb()
    //             console.log(legends.length)
    //             console.log(legendsLength)

    //             if (legends.length === legendsLength) {

    //                 console.log(legendsLength)

    //                 const legendObjects = legends.map(legend => {
    //                     const legendId = legend.id
    //                     const findMemberLegend = find(member.legends, (o) => { return o.legendId === legendId })

    //                     if (findMemberLegend) {
    //                         return { legendId, frequency: findMemberLegend.frequency }

    //                     } else {
    //                         return { legendId, frequency: 0 }

    //                     }
    //                 })


    //                 legendObjects.forEach((legend) => {
    //                     const scoreLegend = find(legends, (o) => {
    //                         return o.id === legend.legendId;
    //                     });
    //                     if (scoreLegend) {
    //                         membersCalculatedScore += scoreLegend.points * legend.frequency;
    //                     }
    //                 })


    //                 // teamScore += membersCalculatedScore;

    //                 if (legends.length !== member.legends.length) {


    //                     db.collection(`games/${url}/members`)
    //                         .doc(member.id)
    //                         .set(
    //                             {
    //                                 legends: [...legendObjects],
    //                                 score: membersCalculatedScore
    //                             },
    //                             { merge: true }
    //                         );
    //                 } else {
    //                     db.collection(`games/${url}/members`)
    //                         .doc(member.id)
    //                         .set(
    //                             {
    //                                 score: membersCalculatedScore
    //                             },
    //                             { merge: true }
    //                         );
    //                 }

    //             }

    //         });

    //     }
    // }, [legends, members, legendsLength])


    const calculateMemberScore = useCallback(() => {
        calculateLegendsLengthInDb()
        if (members && legends && members.length && legends.length && legends.length === legendsLength) {
            let i = 1
        // let teamScore = 0;
        const batch = db.batch()


            members.forEach((member) => {
                let membersCalculatedScore = 0;
               
                    const legendObjects = legends.map(legend => {
                        const legendId = legend.id
                        const findMemberLegend = find(member.legends, (o) => { return o.legendId === legendId })

                        if (findMemberLegend) {
                            return { legendId, frequency: findMemberLegend.frequency }

                        } else {
                            return { legendId, frequency: 0 }

                        }
                    })


                    legendObjects.forEach((legend) => {
                        const scoreLegend = find(legends, (o) => {
                            return o.id === legend.legendId;
                        });
                        if (scoreLegend) {
                            membersCalculatedScore += scoreLegend.points * legend.frequency;
                        }
                    })

                    console.log(legendObjects)

                    // teamScore += membersCalculatedScore;



                        let memberRef  = db.collection(`games/${url}/members`).doc(member.id)
                        batch.update(memberRef , {legends:[...legendObjects] , score:membersCalculatedScore})    
                        // .set(
                            //     {
                            //         legends: [...legendObjects],
                            //         score: membersCalculatedScore
                            //     },
                            //     { merge: true }
                            // );


                            // if(i === members.length){

                         

                            // }
                        
                            // i += 1  

                            console.log('battle Royale')
                          
            });

            batch.commit().then(() => {
            
                setMembersUpdated(true)
            })


        }
}, [legends, members , legendsLength , db , url , calculateLegendsLengthInDb])


    useEffect(() => {

        if(!membersUpdated){
        calculateMemberScore();

        }


    }, [calculateMemberScore])



    return (
        <div className={`col-md-4  col-sm-12 ${div}`}>

            <div className={`team-border-${color}`}>
                <table className="table1">
                    <thead> 

                        <tr>
                            <th className="headingthnum">#</th>
                            <th className="headingthName">Name</th>
                            {/* <th className="headingthScore">Score</th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {members
                            ? members.map((member, index) => (
                                <Member key={member.id} legends={legends} url={url} member={member} members={members} number={second ? index + 11 : index + 1 } color={color} memberLegendsColor={memberLegendsColor} gameId = {gameId}/>
                            ))
                            : null}


                    </tbody>

                </table>
                <h5 className="playerpresent"> Players Present&nbsp;=&nbsp;{members ? members.length : 0} </h5>
            </div>
            <div>
            </div>
        </div >

    );
};

export default BattleRoyaleBoard;