import React, { useState } from 'react'
import { PlayerContext } from '../context/playerProvider'
import firebase from "../firebase/firebase";
import JoinPlayerModal from './JoinPlayerModal'
import { UserContext } from '../context/userProvider'



const JoinPlayerBattle = ({ url, legends, members , gameId }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    const { cookies, cookiesChanged, setCookiesChanged } = React.useContext(PlayerContext)

    const { user } = React.useContext(UserContext)

    const db = firebase.firestore()

    const LeaveMember = () => {
        const player = cookies.get('battleplayer')
        if (player) {
            cookies.remove('battleplayer', { path: '/' })
            // const memberLegends = legends.map(legend => {
            //     return { id: legend.id, frequency: 0 }
            // })
            db.collection(`games/${url}/members`)
                .doc(player.id)
                .set({
                    playing: false
                }, { merge: true }).then(() => {
                    setCookiesChanged(!cookiesChanged)

                })
        }

    }



    const JoinAndLeaveCondition = () => {

        if (cookies.get('battleplayer')) {
            const player = cookies.get('battleplayer')
            return (
                <div className="row">
                    <div className="col-md-12">
                        <div className="media">
                            <div class="avatarbag">
                                <img src={player.avatar} alt="Avatar" class="avatarjoin align-self-start " />
                            </div>
                            <div class="media-body">
                                <div class="joinnamediv">
                                    <h3 class="mt-0 medianam">{player.name}</h3>
                                    <p class="emailjointext">{player.email}</p>
                                    <button class="redbtn" onClick={LeaveMember}>Leave Game</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        } else {
            return (
                <div className="text-center gamification-right-button">
                    <button type="button" className="joinplaybtn" onClick={handleShow}>Join</button>
                    <JoinPlayerModal closePlayerModal={handleClose} legends={legends} url={url} show={show} members={members} />
                </div>
            )

        }

    }


    if (!user || user.uid !== gameId) {

        return (
            JoinAndLeaveCondition()
        )
    } else {
        return null
    }
}

export default JoinPlayerBattle
