import React, { useEffect, useCallback, useState } from 'react'
import firebase from '../firebase/firebase'
import { remove, set, sortBy } from "lodash";
import Baba from '../assets/images/baba.png'
import Avatar from '../assets/images/lion.png'


const TeamSummaryCard = ({ color, image, team, url, legends, members , user }) => {

    if (team) {

        return (
            <div class="col-md-12 col-lg-5 col-xl-5 col-sm-12  ">
                <div class="img_summarystyle">
                    <img class={`summary_img${image}`} src={team.image ? team.image : Avatar} alt="" />
                    <div class="diplayline">
                        <text class="Team_Gamer">
                            {team.name}
                        </text>
                        <h4 class="teamscore">
                            SCORE : {team.score}
                        </h4>
                    </div>
                </div>
                <div>
                    <table class="table table_style">
                        <thead class={`tablehead${color}`}>
                            <tr>
                                <th className="radiusth">Ranking</th>
                                <th></th>
                                <th >PLAYERS</th>
                                {/* {user?
                                    <th>EMAIL</th>
                                    :null} */}
                                
                                <th>TOTAL POINTS</th>

                                {legends ? legends.map((legend, index) => (
                                    <th key={legend.id} className={index === legends.length - 1 ? 'radiusthright' : ''}>{legend.name}</th>

                                ))
                                    : null}
                            </tr>
                        </thead>
                        <tbody>
                            {members ? members.map((member , index) => (
                                <tr key={member.id}>
                                    <td>{index+1}</td>
                                    <td><img src={member.avatar ? member.avatar : Baba} width={30} height={30} alt="avatar" /></td>
                                    <td>{member.name}</td>
                                    {/* {user?
                                    <td>{member.email}</td>
                                    :null} */}
                                    <td>{member.score}</td>
                                    {sortBy(member.legends, ['legendId']).map(legend => (
                                        <td key={legend.legendId}>{legend.frequency}</td>

                                    ))}
                                </tr>

                            )) : null}

                        </tbody>
                    </table>
                </div>

            </div>
        )
    }
    else {
        return null
    }
}

export default TeamSummaryCard
