import React from 'react'
import { Modal, ModalBody } from "reactstrap";




const DeleteModal = ({ member, closeDeleteModal, openedDeleteModal, removeMember, color }) => {

    return (
        <Modal
            isOpen={openedDeleteModal === member.id}
            toggle={closeDeleteModal}
        >
            <ModalBody>
                <div className="modal-block mfp-hide">
                    <section className="panel">
                        <div className={`panel-body card cardborder${color}`}>
                            <div className="modal-wrapper">
                                <div className="modal-text text-center">
                                    <p style={{ fontWeight: "bold" }}>Are you sure that you want <br />To remove {member.name}?</p>
                                    <button className={`btn btn-default modelYesbtn btn${color}yes`} id={member.id} onClick={removeMember}>Yes</button>
                                    <button className="btn btn-default modelNobtn" onClick={closeDeleteModal}>No</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </ModalBody>

        </Modal >
    )
}

export default DeleteModal
