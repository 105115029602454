import React from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../context/userProvider";
// import Cookies from "js-cookie";

const ProtectedSignIn = ({ component: Component, ...rest }) => {
    const { user } = React.useContext(UserContext);
    // const user = Cookies.get("user");

    return (
        <Route
            {...rest}
            render={(props) => {
                if (user && user !== 'waiting') {
                    return <Redirect to="/dashboard" />;

                } else {
                    return <Component {...props} />;

                }
            }}
        />
    );
};

export default ProtectedSignIn;
