import React, { useCallback, useState } from "react";
import firebase from "../firebase/firebase";
import { encode } from "js-base64";
import { UserContext } from "../context/userProvider";
import { Spinner } from "react-bootstrap";
import { remove, toInteger, find } from "lodash";
import Avatar from '../assets/images/lion.png'

import { faTimesCircle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Redirect } from "react-router-dom";





const handleSave = (e, teams, Url, timer, game, type, uid, legends, history) => {
    e.preventDefault();

    const db = firebase.firestore();


    // add game url and UserId
    if (Url) {
        const timeInSeconds = (timer.hours * 60 * 60) + (timer.minutes * 60)

        db.collection('games')
            .where('uid', '==', uid)
            .where('type', '==', type)
            .get()
            .then(snapshot => {
                db.collection("games")
                    .doc(Url)
                    .set(
                        {
                            uid: uid,
                            gameNumber: snapshot.docs.length,
                            endGame: false,
                            time: parseInt(timeInSeconds),
                            resetTime: parseInt(timeInSeconds),
                            name: game,
                            type: type
                        },
                        { merge: true }
                    );
            })

    }
    if (type === 'teamGame') {
        teams.forEach((team) => {
            db.collection("games").doc(Url).collection("teams").doc(team.id).set({
                name: team.name,
                score: team.score,
                headerName: team.headerName,
                image: team.image
            });
        });

    }


    db.collection(`games/${Url}/legends`)
        .get()
        .then((snapshot) => {
            snapshot.forEach((doc) => {
                doc.ref.delete();
            });

            if (legends) {
                addLegends();

            }

        });


    const addLegends = () => {
        legends.forEach((legend) => {
            if (legend.name !== "") {
                db.collection("games")
                    .doc(Url)
                    .collection("legends")
                    .doc(legend.id)
                    .set(
                        {
                            name: legend.name,
                            points: parseInt(legend.points)
                        },
                        { merge: true }
                    );
            }
        });
    };



};

const GameFrom = (props) => {

    const [game, setGame] = useState(null);
    const [gameType, setGameType] = useState()
    const [timer, setTimer] = useState();
    const [Url, setUrl] = useState();
    const [teams, setTeams] = useState([]);
    const [created, setCreated] = useState(false)


    const charLimit = "30"

    const [legends, setlegends] = React.useState('waiting');

    const { user, saveClick, setSaveClick } = React.useContext(UserContext);

    const db = firebase.firestore()


    const initializeTeam2 = () => {
        const id = "teamB" + encode(new Date().getTime());

        const team = {
            name: "",
            id,
            headerName: "Team B",
            score: 0,
            image: "",
            imageLoader: false,
            imageSizeError: ''

        };

        return team;
    };

    const initializeTeam1 = () => {
        const id = "teamA" + encode(new Date().getTime());
        const team = {
            name: "",
            id,
            headerName: "Team A",
            score: 0,
            image: "",
            imageLoader: false,
            imageSizeError: ''
        };

        return team;
    };

    const initializeTeams = useCallback(() => {
        if (gameType === 'teamGame') {
            const team1 = initializeTeam1();
            const team2 = initializeTeam2();

            setTeams([team1, team2]);

        } else {
            setTeams(null)
        }

    }, [gameType])

    const createLegend = useCallback(() => {

        const id = encode(new Date().getTime());
        const legend = { id, name: ``, points: 0 };

        if (legends === null) {
            setlegends([...[], legend]);

        } else {
            setlegends([...legends, legend]);

        }

    }, [legends])

    const getTeamsFromDB = useCallback(() => {
        if (Url && gameType === 'teamGame') {
            db.collection(`games/${Url}/teams`)
                .get()
                .then((snapshot) => {
                    const teams = [];
                    snapshot.docs.forEach((doc) => {
                        const id = doc.id;
                        teams.push({ ...doc.data(), id });
                    });
                    if (teams.length) {
                        setTeams(teams);
                    }
                });
        }


    }, [Url, db, gameType])

    const getLegendsFromDB = useCallback(() => {
        if (Url) {

            db.collection(`games/${Url}/legends`)
                .get()
                .then((snapshot) => {
                    const retrievedLegends = [];
                    snapshot.docs.forEach((doc) => {
                        const id = doc.id;
                        retrievedLegends.push({ ...doc.data(), id });
                    });
                    if (retrievedLegends.length) {
                        setlegends(retrievedLegends);
                    } else {
                        setlegends(null)
                    }
                });
        }
    }, [Url, db])

    const convertTime = (timeInSeconds) => {
        let timerCopy
        if (timeInSeconds > 0) {
            let minutes = Math.floor(timeInSeconds / 60);
            let hours = Math.floor(minutes / 60)
            minutes = minutes % 60

            if (minutes <= 9 && minutes >0) {
                minutes = '0' + minutes

            }

            if (hours <= 9 && hours > 0) {
                hours = '0' + hours

            }

            timerCopy = { hours: hours, minutes: minutes }


        } else {
            timerCopy = { hours: 0, minutes: 0 }
        }


        return timerCopy

    }





    const initializeTimer = () => {
        setTimer(null)
        const timerCopy = { hours: 0, minutes: 0 }

        setTimer(timerCopy)

    }

    const generateUrl = useCallback(() => {
        if (!Url) {


            const date = new Date();
            const gameId = encode(date.getTime());
            setUrl(gameId);
        }


    }, [Url])

    const getNameUrlAndTimer = useCallback(() => {
        db.collection("games")
            .where('uid', '==', user.uid)
            .where('endGame', '==', false)
            .onSnapshot((snapshot) => {
                if (snapshot.docs.length) {
                    snapshot.docs.forEach((doc) => {
                        setGame(doc.data().name)
                        setGameType(doc.data().type)
                        const timer = convertTime(doc.data().resetTime)
                        setTimer(timer)
                        setCreated(true)
                        setUrl(doc.id);
                        if (doc.data().type === 'battleRoyale') {
                            setTeams(null)
                        }

                    });

                } else {
                    initializeTeams();
                    initializeTimer();
                    if (!gameType) {
                        setGameType('teamGame')

                    }
                    setGame('')
                    generateUrl()


                }



            });

    }, [db, user, initializeTeams, gameType, generateUrl])


    const setHours = (e) => {

        if (!(/[0-9]$/.test(e.target.value))) {
            e.target.value = ''
        } else {

            const hours = toInteger(e.target.value)

            if (hours >= 0 && hours <= 1000) {

                timer.hours = hours

                setTimer(timer)
            } else {
                e.target.value = timer.hours

            }

        }

    }


    const setMinutes = (e) => {

        console.log(timer)
        if (!(/[0-9]$/.test(e.target.value))) {
            e.target.value = ''
        } else {
            const minutes = toInteger(e.target.value)
            if (minutes >= 0 && minutes <= 60) {

                timer.minutes = minutes

                setTimer(timer)
            } else {
                e.target.value = timer.minutes

            }

        }





    }



    React.useEffect(() => {

        getNameUrlAndTimer()

        getTeamsFromDB()
        getLegendsFromDB()

    }, [getNameUrlAndTimer, getTeamsFromDB, getLegendsFromDB]);



    const removeLegend = (e) => {
        const legendsCopy = [...legends];

        remove(legendsCopy, (o) => {
            return o.id === e.target.id;
        });

        setlegends([...legendsCopy]);
    };



    const setLegendName = (e) => {
        const legendsCopy = legends;
        for (var i in legendsCopy) {
            if (legendsCopy[i].id === e.target.id) {
                legendsCopy[i].name = e.target.value;
                break; //Stop this loop, we found it!
            }
        }
        setlegends([...legendsCopy]);
    };

    const setLegendPoints = (e) => {


        if (!(/[0-9]$/.test(e.target.value))) {

            e.target.value = ''

        }
        else {
            const value = toInteger(e.target.value)

            if (value >= 0 && value <= 1000) {
                const legendsCopy = legends;
                for (var i in legendsCopy) {
                    if (legendsCopy[i].id === e.target.id) {
                        legendsCopy[i].points = value;
                        break; //Stop this loop, we found it!
                    }
                }
                setlegends([...legendsCopy]);
            }
            else {

                let legend = find(legends, (o) => { return o.id === e.target.id })
                e.target.value = legend.points

            }

        }

    };

    const setTeamName = (e) => {
        const teamsCopy = teams;
        for (var i in teamsCopy) {
            if (teamsCopy[i].id === e.target.id) {
                teamsCopy[i].name = e.target.value;
                break; //Stop this loop, we found it!
            }
        }
        setTeams([...teamsCopy]);
    };

    const invertTeamLoader = (id) => {
        const teamsCopy = teams;
        for (var i in teamsCopy) {
            if (teamsCopy[i].id === id) {
                teamsCopy[i].imageLoader = !teamsCopy[i].imageLoader;
                break; //Stop this loop, we found it!
            }
        }
        setTeams([...teamsCopy]);
    };

    const showErrorMessage = (id) => {

        const teamsCopy = teams;
        for (var i in teamsCopy) {
            if (teamsCopy[i].id === id) {
                teamsCopy[i].imageSizeError = 'Image size is greater than 1MB'
                break; //Stop this loop, we found it!
            }
        }
        setTeams([...teamsCopy]);

    }

    const firebaseUpload = (id, file) => {

        const imageSize = (file.size / 1024) / 1024


        if (imageSize > 1) {
            showErrorMessage(id)

        } else {
            invertTeamLoader(id)
            const date = new Date();
            const imageId = encode(date.getTime());

            const imagesRef = firebase.storage().ref("images").child(imageId);

            const uploadtoFireStore = () => {
                imagesRef.getDownloadURL().then((url) => {
                    const teamsCopy = teams;
                    for (var i in teamsCopy) {
                        if (teamsCopy[i].id === id) {
                            teamsCopy[i].image = url;
                            break; //Stop this loop, we found it!
                        }
                    }
                    setTeams([...teamsCopy]);
                    invertTeamLoader(id);
                });
            };

            imagesRef.put(file).on(
                firebase.storage.TaskState,
                (snapshot) => {
                    console.log(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                null,
                uploadtoFireStore
            );

        }



    }

    const uploadImage = async (e) => {
        const file = e.target.files[0];
        const id = e.target.id



        firebaseUpload(id, file)



    };

    const dropUpload = (e) => {

        const id = e.currentTarget.id
        const file = e.dataTransfer.files[0]


        firebaseUpload(id, file)

    }

    const teamsHandlingForBattleRotale = () => {
        if (teams === null) {
            return null
        } else {
            return (
                <Spinner
                    animation="border"
                    className="pageloder"

                    variant="primary"


                />
            )
        }

    }

    const handlingLegends = () => {
        if (legends === null) {
            return null
        } else {
            return (
                <Spinner
                    animation="border"
                    className="pageloder"
                    variant="primary"


                />
            )
        }

    }


    return (
        <div className="gamecard">

            {/* <div className=" grid grid-mobile"> */}
            <div class="row" >
                <div class="col-md-6 gameinformationdiv">

                    <h3 className="entergameinfo">Enter Game Information</h3>
                    <div >

                        <div className="upperdiv">



                            <h3 className="gametype"> Select game type</h3> &nbsp;                      
                             <span tooltip="Select game type" flow="right">

                                <button class="needhelp"> ?</button >
                            </span>
                            <br />
                            {gameType ?
                                <>
                                    <div class="radioinline">
                                        <input className="heightinput2" type="radio" name="gametype"
                                            defaultChecked={gameType === 'teamGame'}
                                            disabled={created && gameType === 'battleRoyale'}
                                            label="Team Game"
                                            onChange={(e) => { setGameType(e.target.value); setUrl(null); setGame(null); setTimer(null); setTeams([]); setlegends('waiting') }}
                                            id="teamGame"
                                            value="teamGame"
                                        />
                                        <label className="labelinput1" >Team Game</label>  <br />

                                        <input className="heightinput2" type="radio" name="gametype"
                                            value="battleRoyale"
                                            disabled={created && gameType === 'teamGame'}
                                            defaultChecked={gameType === 'battleRoyale'}
                                            label="Battle Royale"
                                            onChange={(e) => { setGameType(e.target.value); setUrl(null); setGame(null); setTimer(null); setTeams([]); setlegends('waiting') }}
                                            id="battleRoyale"
                                        />
                                        <label className="labelinput1" >Battle Royale</label>

                                    </div>
                                </>
                                :
                                <Spinner
                                    animation="grow"
                                    variant="primary"
                                />
                            }



                            <br />
                            <br />
                            <label className="labelinput23" >Game Name</label> &nbsp;<span tooltip="Enter Game Name" flow="right">

                                <button class="needhelp"> ?</button >
                            </span> <br />
                            {game !== null ?

                                <input
                                    className="heightinput"
                                    type="text"
                                    maxLength={charLimit}
                                    id="gamename"
                                    value={game}
                                    placeholder="Enter Game Name"
                                    onChange={(e) => { setGame(e.target.value) }}
                                />
                                : null}



                            {timer ? (


                                <div>
                                    <label className="Timmer" >Timer</label> &nbsp;
                                    <span tooltip="Set timer for game" flow="right">

                                        <button class="needhelp"> ?</button >
                                    </span> <br />
                                    <input className="heightinput1"
                                        required
                                        defaultValue={timer.hours === 0 ? '' : timer.hours}
                                        type="text"
                                        maxLength="2"
                                        placeholder="00"
                                        onChange={setHours}
                                    />

                                    <span class="HRS">HRS </span>

                                    <span className="colan"> : </span>

                                    <input
                                        className="heightinput1"
                                        placeholder="00"
                                        id="Timmer"
                                        required
                                        maxLength="2"
                                        defaultValue={timer.minutes === 0 ? '' : timer.minutes}
                                        type="text"
                                        onChange={setMinutes}
                                    />
                                    <span class="MIN">MIN</span>

                                    <br />
                                </div>
                            ) :

                                <Spinner
                                    className="pageloder"
                                    animation="border"
                                    variant="primary"
                                />}

                        </div>

                    </div>

                    <>

                        <label className="urllink" >URL</label> &nbsp; <span tooltip="Share this link with your participants to join the game" flow="right">

                            <button class="needhelp"> ?</button >
                        </span>
                        <br />
                        <input

                            className="heightinput URL gameurl"
                            type="text"
                            onChange={() => { return null }}
                            value={`${window.location.host}/${gameType === 'teamGame' ? 'scoreboard' : 'battleroyale'}/${Url}`}
                        />
                    </>



                    <div className="row">
                        {teams && teams.length ? (
                            teams.map(
                                ({
                                    id,
                                    name,
                                    headerName,
                                    image,
                                    imageLoader,
                                    imageSizeError
                                }) => (
                                    <React.Fragment key={id}>
                                        <div className="col-md-6 col-sm-12">
                                            <label className="labelinput">{headerName}</label> &nbsp;
                                                <span tooltip="Enter Team Name" flow="right">

                                                <button class="needhelp"> ?</button >
                                            </span>

                                            <br />
                                            <input
                                                className="heightinput3"
                                                required
                                                maxLength={charLimit}

                                                defaultValue={
                                                    name
                                                }
                                                type="text"
                                                size={8}
                                                id={id}
                                                onChange={
                                                    setTeamName
                                                }
                                                placeholder={`Enter ${headerName} Name`}


                                            />
                                            <br />

                                        </div>

                                        <div className="col-md-6 col-sm-12" id={id} onDrop={dropUpload}>
                                            <div className="setpos">
                                                <label className="labelinput23 ">{headerName} Image</label> &nbsp;

                                                    <span tooltip="Upload Team Image" flow="right">

                                                    <button class="needhelp"> ?</button >
                                                </span>


                                                <br />
                                                <input className="heightinput3 " type="text" id="fname" placeholder="Image Url" /><br />

                                                <label className="btn btn-primary uploadbtn" >
                                                    Upload<input
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        id={id}
                                                        name={name}
                                                        onChange={
                                                            uploadImage
                                                        }
                                                    />
                                                </label>
                                            </div>

                                            {imageSizeError && imageSizeError.length ?
                                                <p style={{ color: 'red', fontSize: 10 }}>{imageSizeError}</p>
                                                : null}

                                            {imageLoader ? (
                                                <Spinner
                                                    animation="border"
                                                    variant="primary"
                                                    size="sm"
                                                    className="spinnerstyle"
                                                />
                                            ) : (
                                                    <img
                                                        width="80"
                                                        className="imgupload"
                                                        alt="img"
                                                        src={image ? image : Avatar}
                                                    />
                                                )}



                                        </div>
                                    </React.Fragment>
                                ))) : (
                                teamsHandlingForBattleRotale()

                            )}
                    </div>


                </div>
                {/* 
                <div className="tableinfo"> */}
                <div class="col-md-6">
                    <div className="card2">

                        <table>
                            <thead>
                                <tr>
                                    <th className="Activities"><text> Metrices </text></th>
                                    <th></th>
                                    <th className="AddLegends " onClick={createLegend} ><span className="faplusicon">+  </span> </th>

                                </tr>

                                <tr>
                                    <th>Name</th>
                                    <th>Points</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {legends && legends !== 'waiting' ? (
                                    legends.map(({ id, name, points }) => (
                                        <tr key={id}>
                                            <td>
                                                <input
                                                    maxLength={charLimit}

                                                    className="legendsinput lenthsize"
                                                    type="text"
                                                    id={id}
                                                    defaultValue={name}
                                                    placeholder="Metric"
                                                    onChange={setLegendName}

                                                />
                                            </td>

                                            <td>
                                                <input
                                                    className="legendsinputzero lenthsize pointlegth"
                                                    id={id}
                                                    defaultValue={points === 0 ?'':points}
                                                    type="text"
                                                    maxLength="3"
                                                    placeholder="0"
                                                    onChange={setLegendPoints}

                                                />
                                            </td>
                                            <td className="fa-times-circle1"><FontAwesomeIcon id={id} icon={faTimesCircle} onClick={removeLegend} /></td>
                                        </tr>
                                    ))) : (
                                        handlingLegends()


                                    )

                                }
                            </tbody>
                        </table>
                        <div>

                        </div>

                    </div>

                    <div className="Informationbtn">
                        <button
                            className="CreateSession"
                            onClick={(e) => {
                                setSaveClick(!saveClick)
                                handleSave(e, teams, Url, timer, game, gameType, user.uid, legends, props.history);
                            }}

                        >
                            <Link to={`${gameType === 'teamGame' ? 'scoreboard' : 'battleRoyale'}/${Url}`}>
                                Save & Next

                            </Link>

                        </button>

                    </div>

                </div>

            </div>
        </div >
    );
};

export default GameFrom;