import React, { useState, useEffect, useCallback } from 'react'
import firebase from '../firebase/firebase'
import { remove, set, sortBy , orderBy} from "lodash";
import { CSVLink } from "react-csv";
import ReactToPdf from 'react-to-pdf'
import html2pdf from 'html2pdf.js';
import Baba from '../assets/images/baba.png'
import { UserContext } from '../context/userProvider'

const BattleRoyaleSummary = ({ url, battle }) => {
    const db = firebase.firestore()

    // const ref = React.createRef();
    const [members, setMembers] = useState()
    const [legends, setLegends] = useState()
    const [csvData, setCsvData] = useState()
    const [gameScore, setTotalGameScore] = useState(0)
    const [gameCreatorId , setGameCreateorId] = useState()
    const [game, setGame] = useState()

    const {user} = React.useContext(UserContext)


    const [eachLegendPoints, setEachLegendPoints] = useState()

    const getGame = useCallback(() => {
        db.collection(`games`)
            .doc(url)
            .get()
            .then(doc => {
                setGame(doc.data())
                setGameCreateorId(doc.data().uid)

            })


    }, [url, db])


    const getMembersFromDB = useCallback(() => {

        let battleRoyaleMembers = [];

        db.collection(`games/${url}/members`)
            .where('playing', '==', true)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let id = doc.id
                    battleRoyaleMembers.push({ id, ...doc.data() })
                })
                battleRoyaleMembers = orderBy(battleRoyaleMembers,['score'],['desc'])
                setMembers(battleRoyaleMembers)
            })


    }, [db, url])

    const getLegendsFromDB = useCallback(() => {

        let battleRoyaleLegends = [];

        db.collection(`games/${url}/legends`)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let id = doc.id
                    battleRoyaleLegends.push({ id, ...doc.data() })
                })

                battleRoyaleLegends = sortBy(battleRoyaleLegends, ['id'])
                setLegends(battleRoyaleLegends)

            })


    }, [db, url])

    useEffect(() => {
        if (legends && members) {
            let totalScoreOfLegends = []
            let totalLegendsOfGame = 0

            legends.forEach(legend => {
                totalScoreOfLegends.push({ ...legend, totalOccurance: 0, frequency: 0 })

            });

            members.forEach(member => {

                member.legends.forEach(legend => {
                    totalLegendsOfGame += legend.frequency
                    let updatedLegend = remove(totalScoreOfLegends, (o) => { return o.id === legend.legendId })
                    if (updatedLegend[0]) {
                        updatedLegend[0].totalOccurance = updatedLegend[0].totalOccurance + legend.frequency
                        totalScoreOfLegends.push(updatedLegend[0])
                    }



                })


            });

            console.log(totalLegendsOfGame)


            setTotalGameScore(totalLegendsOfGame)
            setEachLegendPoints(totalScoreOfLegends)
        }

    }, [legends, members])

    useEffect(() => {

        if (legends && members) {

            const header = ['Players', 'Email', 'Total Points']
            legends.forEach(legend => {
                header.push(legend.name)
            })

            const csvRow = []

            const gameName = ['', '', 'Game Summary', '']
            const emptyRow = ['', '', '', '']


            csvRow.push(gameName)
            csvRow.push(emptyRow)
            csvRow.push(header)

            members.forEach(member => {
                let row = []

                row.push(member.name)
                row.push(member.email)
                row.push(member.score)

                sortBy(member.legends, ['legendId']).forEach(legend => {
                    row.push(legend.frequency)
                })

                csvRow.push(row)
            })

            // csvRow.push([])
            // console.log(Math.ceil(csvRow[2].length / 2))

            // var legendOccuranceHeader = []
            // for (let i = 1; i <= csvRow[3].length; i++) {
            //     if (i === Math.floor(csvRow[2].length / 2)) {
            //         legendOccuranceHeader.push("Legends Occurrence")

            //     } else {
            //         legendOccuranceHeader.push('')
            //     }
            // }
            // csvRow.push(legendOccuranceHeader)
            // csvRow.push([])


            // if (eachLegendPoints) {
            //     eachLegendPoints.forEach(legend => {
            //         const row = []
            //         row.push('')
            //         row.push(legend.totalOccurance)
            //         row.push('')
            //         row.push(legend.name)

            //         csvRow.push(row)
            //     })


            // }


            setCsvData(csvRow)


        }


    }, [members, legends, eachLegendPoints])


    useEffect(() => {
        getMembersFromDB()
        getLegendsFromDB()
        getGame()


    }, [getMembersFromDB, getLegendsFromDB, getGame])

    const generatepdf = () => {

        const download = window.document.getElementById('download')
        const sidebar = window.document.getElementById('sidebar')
        const navbar = window.document.getElementById('navbar')


        download.style.display = 'none'
        sidebar.style.display = 'none'
        navbar.style.display = 'none'




        window.print()


        download.style.display = 'block'
        navbar.style.display = 'block'
        sidebar.style.display = 'block'
        // const elementToPrint = window.document.getElementById('battleSummary')
        // console.log(elementToPrint)

        // var opt = {
        //     filename: `${game.name}.pdf`,
        //     margin: 12,
        //     image: { type: 'png', quality: 0.98 },
        //     jsPDF: { orientation: 'landscape' },
        // }

        // html2pdf()
        //     .set(opt)
        //     .from(elementToPrint)
        //     .save();
    }



    if (members) {

        return (
            <>
                <div id="battleSummary">

                    <div class="row container-fluid" >
                        {/* <div className="col-md-4"></div> */}
                        <div className="col-md-12 test"></div>
                        <div className="paperlooks " >



                            <div class="battalRoyalH">
                                <h3 className="gamesummaryH">
                                    GAME SUMMARY
    </h3>
                                <text class="BattleroyalH">{game ? game.name : 'battleRoyale'}</text>

                            </div>

                            <table class="table table_style">
                                <thead class="tableheadpink">
                                    <tr>
                                        <th className="radiusth">Ranking</th>
                                        <th></th>
                                        <th >PLAYERS</th>
                                        {/* {user?
                                        <th>EMAIL</th>
                                        :null} */}
                                        <th>TOTAL POINTS</th>
                                        {legends ? legends.map((legend, index) => (
                                            <th key={legend.id} className={index === legends.length - 1 ? 'radiusthright' : ''}>{legend.name}</th>

                                        ))
                                            : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {members ? members.map((member,index) => (
                                        <tr className="tbodystyle" key={member.id}>
                                            <td>{index+1}</td>
                                            <td><img src={member.avatar ? member.avatar : Baba} width={30} height={30} alt="avatar" /></td>
                                            <td>{member.name}</td>
                                            {/* {user?
                                            <td>{member.email}</td>
                                            :null} */}
                                            <td>{member.score}</td>
                                            {sortBy(member.legends, ['legendId']).map(legend => (
                                                <td key={legend.legendId}>{legend.frequency}</td>

                                            ))}
                                        </tr>

                                    )) : null}


                                </tbody>
                            </table>




                            <div class="row container-fluid">
                                <div class="col-md-4"></div>
                                <div class="col-md-12 rcornerspinkbattle">
                                    <div class="rcorners">
                                        {eachLegendPoints ?
                                            eachLegendPoints.map((legend, index) => {
                                                let percentage = (legend.totalOccurance / gameScore) * 100

                                                return (
                                                    <>
                                                        <div class="mainprogess">

                                                            <div class="colcombine leftry">{legend.totalOccurance}</div>
                                                            <div class="colcombine rightry">
                                                                <div class="progress progresslenthB">
                                                                    <div class="progress-bar progressbarColourpink " style={{ width: `${percentage}%` }}></div>
                                                                    <div class="progress-bar "></div>

                                                                </div>

                                                            </div>
                                                            <div class="colcombine rightry1">{legend.name}</div>
                                                        </div>

                                                        <br />
                                                    </>


                                                )
                                            })

                                            : null}



                                    </div>

                                </div>



                            </div>


                            <div className="row ">
                                {/* <div className="col-md-4">
            </div> */}
                                <div className="col-md-12">


                                    <div class="donwloadtextstyle" id="download">
                                        <text>DOWNLOAD RESULT AS</text>

                                        <button className="btn_download" onClick={generatepdf}>
                                            PDF
            </button>

                                        {csvData && user && user.uid === gameCreatorId ?
                                            <button className="btn_download">
                                                <CSVLink data={csvData} filename={game ? `${game.name}.csv` : 'battleRoyale.csv'}> CSV </CSVLink>
                                            </button>
                                            : null}


                                        {/* <button onClick={generateCSV} class="btn_download">
                    </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    } else {
        return null
    }
}

export default BattleRoyaleSummary
