import React from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../context/userProvider";
import { Spinner } from "react-bootstrap";

const ProtectedDashboard = ({ component: Component, ...rest }) => {
    const { user } = React.useContext(UserContext);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (user === "waiting") {
                    <Spinner animation="grow" variant="primary" />;

                } else if (user === null) {
                    return <Redirect to="/" />;
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
};

export default ProtectedDashboard;
