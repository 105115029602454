import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import firebase from '../firebase/firebase'
import { UserContext } from '../context/userProvider'
import { TimerContext } from '../context/timerProvider'
import {
    DashBoardBlue,
    ScoreBoardBlue,
    DashBoardWhite,
    ScoreBoardWhite,
    BattleRoyaleBlue,
    BattleRoyaleWhite,
} from '../assets/icons/index'
import BattleGameBoard from './BattleGameBoard'

const SideBar = (props) => {
    const { user } = React.useContext(UserContext)
    const { url } = React.useContext(TimerContext)
    const [gameType, setGameType] = React.useState('')
    const [gameUrl, setGameUrl] = React.useState('')
    const [gameCreatorId, setGameCreatorID] = React.useState()
    const db = firebase.firestore()

    const [activeTab, setActiveTab] = React.useState()

    const getCreaterId = async () => {
        if (url) {
        }
    }

    useEffect(async () => {
        if (url) {
            const game = await db.collection('games').doc(url).get()
            if(game.data()){
                setGameCreatorID(game.data().uid)

            }
        }
    }, [url])

    const getUserUrl = React.useCallback(() => {
        let unsubscribe

        if (user !== 'waiting' && user !== null) {
            let latestTeamGame = 0
            let latestBattleRoyale = 0
            unsubscribe = db
                .collection('games')
                .where('uid', '==', user.uid)
                .where('endGame', '==', false)
                .onSnapshot((snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        if (
                            doc.data().type === 'teamGame' &&
                            doc.data().gameNumber >= latestTeamGame
                        ) {
                            latestTeamGame = doc.data().gameNumber
                            setGameUrl(doc.id)
                            setGameType(doc.data().type)
                        } else if (
                            doc.data().type === 'battleRoyale' &&
                            doc.data().gameNumber >= latestBattleRoyale
                        ) {
                            latestBattleRoyale = doc.data().gameNumber
                            setGameUrl(doc.id)
                            setGameType(doc.data().type)
                        }
                    })
                })
        }

        console.log(window.location.pathname)
        if (window.location.pathname.includes('dashboard')) {
            setActiveTab('dashboard')
        } else {
            setActiveTab('scoreboard')
        }

        return unsubscribe
    }, [user, window.location.pathname])

    React.useEffect(() => {
        const unsubscribe = getUserUrl()

        return () => {
            setGameUrl('')
            
        }
    }, [getUserUrl])



    if (user === 'waiting') {
        return <Spinner animation="grow" variant="primary" />
    } else if (user) {

        if (gameCreatorId && user.uid === gameCreatorId) {
            return (
                <div className="sidenav" id="sidebar">
                    <h3 className="sports">Menu</h3>
                    <span
                        className={activeTab === 'dashboard' ? `btnactive` : ''}
                    >
                        <Link
                            to="/dashboard"
                            onClick={() => {
                                setActiveTab('dashboard')
                            }}
                        >
                            {' '}
                            <img
                                className={
                                    activeTab === 'dashboard'
                                        ? `imgsidebar`
                                        : 'imgsidebar1'
                                }
                                src={
                                    activeTab === 'dashboard'
                                        ? DashBoardBlue
                                        : DashBoardWhite
                                }
                                alt=""
                            />{' '}
                        </Link>
                    </span>
                    <br />
                    <>
                        <span
                            className={
                                activeTab === 'scoreboard' ? `btnactive` : ''
                            }
                        >
                            <Link
                                to={
                                    gameType === 'teamGame'
                                        ? `/scoreboard/${gameUrl}`
                                        : `/battleroyale/${gameUrl}`
                                }
                                onClick={() => {
                                    setActiveTab('scoreboard')
                                }}
                            >
                                {' '}
                                <img
                                    className={
                                        activeTab === 'scoreboard'
                                            ? `imgsidebar`
                                            : 'imgsidebar1'
                                    }
                                    src={
                                        activeTab === 'scoreboard'
                                            ? ScoreBoardBlue
                                            : ScoreBoardWhite
                                    }
                                    alt=""
                                />{' '}
                            </Link>
                        </span>

                        {/* <span className={activeTab === 'battleroyale' ? `btnactive` : ''}>
                            <Link to={`/battleroyale/${battleGameUrl}`} onClick={() => { setActiveTab('battleroyale') }}>  <img className={activeTab === 'battleroyale' ? `imgsidebar` : 'imgsidebar1'} src={activeTab === 'battleroyale' ? BattleRoyaleBlue : BattleRoyaleWhite} alt="" />  </Link>
                        </span> */}
                    </>
                </div>
            )
        } else if (!gameCreatorId) {
            return (
                <div className="sidenav" id="sidebar">
                    <h3 className="sports">Menu</h3>
                    <span
                        className={activeTab === 'dashboard' ? `btnactive` : ''}
                    >
                        <Link
                            to="/dashboard"
                            onClick={() => {
                                setActiveTab('dashboard')
                            }}
                        >
                            {' '}
                            <img
                                className={
                                    activeTab === 'dashboard'
                                        ? `imgsidebar`
                                        : 'imgsidebar1'
                                }
                                src={
                                    activeTab === 'dashboard'
                                        ? DashBoardBlue
                                        : DashBoardWhite
                                }
                                alt=""
                            />{' '}
                        </Link>
                    </span>
                    <br />
                    <>
                        <span
                            className={
                                activeTab === 'scoreboard' ? `btnactive` : ''
                            }
                        >
                            <Link
                                to={
                                    gameType === 'teamGame'
                                        ? `/scoreboard/${gameUrl}`
                                        : `/battleroyale/${gameUrl}`
                                }
                                onClick={() => {
                                    setActiveTab('scoreboard')
                                }}
                            >
                                {' '}
                                <img
                                    className={
                                        activeTab === 'scoreboard'
                                            ? `imgsidebar`
                                            : 'imgsidebar1'
                                    }
                                    src={
                                        activeTab === 'scoreboard'
                                            ? ScoreBoardBlue
                                            : ScoreBoardWhite
                                    }
                                    alt=""
                                />{' '}
                            </Link>
                        </span>

                        {/* <span className={activeTab === 'battleroyale' ? `btnactive` : ''}>
                            <Link to={`/battleroyale/${battleGameUrl}`} onClick={() => { setActiveTab('battleroyale') }}>  <img className={activeTab === 'battleroyale' ? `imgsidebar` : 'imgsidebar1'} src={activeTab === 'battleroyale' ? BattleRoyaleBlue : BattleRoyaleWhite} alt="" />  </Link>
                        </span> */}
                    </>
                </div>
            )
        } else {
            return null
        }
    } else {
        return null
    }
}

export default SideBar
