import React, { useEffect, useState } from 'react'
import { remove, set, sortBy } from "lodash";


const LegendsProgressSummary = ({ eachLegendPoints, color, team, progress }) => {


    const CountTotalLegendsOfTeam = () => {
        let sumOfOccurance = 0
        eachLegendPoints.forEach(legend => {
            sumOfOccurance += legend.totalOccurance
        });
        return sumOfOccurance
    }

    return (
        <div class="col-md-12 col-lg-5 col-xl-5 col-sm-12">
        <div class={`rcorners${color}`}>
            {eachLegendPoints ?
                eachLegendPoints.map((legend, index) => {
                    let percentage = Math.ceil((legend.totalOccurance / CountTotalLegendsOfTeam()) * 100)
                    return (
                        <React.Fragment key={index}>
                            <div class={`mainprogess ${progress}`}>

                                <div class={`colcombine leftry ${progress}`}>{legend.totalOccurance}</div>
                                <div class={`colcombine rightry ${progress}`} >
                                    <div class="colcombine rightry ">

                                        <div class={`progress ${progress}`}>
                                            <div class={`progress-bar progressbarColour${color}`} style={{ width: `${percentage}%` }}></div>
                                            <div class="progress-bar" style={{ width: "40%" }}></div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br />
                        </React.Fragment>


                    )
                })

                : null}

        </div >

    </div>
    )
}

export default LegendsProgressSummary
