import React, { useEffect, useRef } from 'react'
import firebase from '../firebase/firebase'
import { toUpper, toLower } from 'lodash'
import { PlayerContext } from '../context/playerProvider'
import { Modal, Form, Col, Row } from 'react-bootstrap'
import Baba from '../assets/images/baba.png'
import Beared from '../assets/images/beared.jpg'
import {UserContext} from '../context/userProvider'





const JoinPlayerModal = ({ teams, closePlayerModal, legends, url, show, color, members }) => {

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState()
    const [selectedTeam, setSelectedTeam] = React.useState()
    const [selectedAvatar, setSelectedAvatar] = React.useState()
    const avatars = React.useRef([])
    const emailInput = React.useRef()
    const {user} = React.useContext(UserContext)


    const { cookies, cookiesChanged, setCookiesChanged } = React.useContext(PlayerContext)


    const db = firebase.firestore()
    console.log(user)

    const addPlayer = async () => {

        if (name && email) {

            if (validateEmail(email)) {
                emailInput.current.style.borderColor = "black"


                let collectionPath;
                var limit;
                var size;
                console.log(teams)


                if (teams) {
                    var defaultTeam = teams[0].id

                    collectionPath = `games/${url}/teams/${selectedTeam ? selectedTeam : defaultTeam}/members`
                    const memberQuery = await db.collection(collectionPath).where('playing', '==', true).get()
                    size = memberQuery.docs.length
                    limit = 10
                } else {
                    collectionPath = `games/${url}/members`
                    size = members.length
                    limit = 20
                }

                if (size < limit) {

                    console.log(collectionPath)
                    db.collectionGroup(`members`)
                        .where("gameId", '==', url)
                        .orderBy("email")
                        .startAt(toUpper(email))
                        .endAt(toLower(email) + "\uf8ff")
                        .get()
                        .then((snapshot) => {
                            let record = false;
                            snapshot.docs.forEach((doc) => {

                                if (toLower(doc.data().email) === toLower(email)) {
                                    let id = doc.id
                                    let ref = doc.ref
                                    record = { ...doc.data(), id, ref };
                                }
                            });


                            if (record) {
                                if (record.playing === true) {

                                    alert("Player already exists")

                                } else {
                                    const legendObjects = legends.map(legend => {
                                        const legendId = legend.id
                                        const frequency = 0
                                        return { legendId, frequency }
                                    })

                                    if (teams) {

                                        const email = record.email
                                        record.ref.delete()


                                        db.collection(collectionPath)
                                            .add({
                                                name: name,
                                                email: email,
                                                gameId: url,
                                                avatar: selectedAvatar,
                                                type: 'joined',
                                                playing: true,
                                                reactions: [],
                                                score: 0,
                                                legends: [...legendObjects]
                                            }).then(doc => {
                                                cookies.set('teamplayer', { name: name, teamId: selectedTeam ? selectedTeam : defaultTeam, avatar: selectedAvatar ? selectedAvatar : record.avatar, email: record.email, id: doc.id }, { path: '/' });
                                                setCookiesChanged(!cookiesChanged)

                                            })


                                    } else {
                                        console.log(record)
                                        db.collection(collectionPath)
                                            .doc(record.id)
                                            .set({

                                                name: name,
                                                avatar: selectedAvatar,
                                                type: 'joined',
                                                playing: true,
                                                reactions: [],
                                                score: 0,
                                                legends: [...legendObjects]
                                            }, { merge: true })

                                        cookies.set('battleplayer', { name: name, avatar: selectedAvatar, email: record.email, id: record.id }, { path: '/' });
                                        setCookiesChanged(!cookiesChanged)

                                    }


                                }

                            } else {
                                const legendObjects = legends.map(legend => {
                                    const legendId = legend.id
                                    const frequency = 0
                                    return { legendId, frequency }
                                })

                                db.collection(collectionPath)
                                    .add({

                                        name: name,
                                        email: email,
                                        gameId: url,
                                        playing: true,
                                        type: 'joined',
                                        avatar: selectedAvatar,
                                        score: 0,
                                        legends: [...legendObjects]


                                    }).then(result => {
                                        if (teams) {
                                            cookies.set('teamplayer', { name, teamId: selectedTeam ? selectedTeam : defaultTeam, avatar: selectedAvatar, email, id: result.id }, { path: '/' });
                                            setCookiesChanged(!cookiesChanged)

                                        } else {
                                            cookies.set('battleplayer', { name, avatar: selectedAvatar, email, id: result.id }, { path: '/' });
                                            setCookiesChanged(!cookiesChanged)

                                        }
                                    })





                            }
                            closePlayerModal()
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                } else {
                    alert("Team is Full")
                }
            }
            else {
                emailInput.current.style.borderColor = 'red'
                emailInput.current.value = email
                setEmail('invalid')
            }
        } else {
            alert("Enter Name and Email");
        }
    };

    // useEffect(aync ()=>{
    //     if(user){
    //         const admin  = await db.collection('admin').doc(user.uid).get()
    //         setName(admin.data().name) 
    //         setEmail(user.email)
    
    //     }
    // },[user])

    useEffect(async() => {
        if(user){
            const admin  = await db.collection('admin').doc(user.uid).get()
            setName(admin.data().name) 
            setEmail(user.email)
    
        }
    },[user])

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }




    const getImage = async (ref) => {

        const url = await ref.getDownloadURL()
        if (!selectedAvatar) {
            setSelectedAvatar(url)

        }
        avatars.current.push(url)

    }

    useEffect(() => {
        var storage = firebase.storage();
        if (avatars.current.length === 0) {
            for (let i = 1; i <= 5; i++) {

                var pathReference = storage.ref(`images/avatar${i}.png`);
                getImage(pathReference)

            }
        }

    }, [])




    return (
        <Modal
            show={show}
            onHide={closePlayerModal}
            backdrop="static"
            keyboard={false}
        >

            <Modal.Body

            ><div id="modalCenter" className="modal-block mfp-hide">
                    <section className="panel">
                        <div className="panel-body cardjoin blue-border" >
                            <div className="modal-wrapper p-4">
                                <div className="modal-text text-left    ">
                                    <h4 style={{ fontWeight: 'bold' }}>Enter Details</h4>


                                    <input type="text" placeholder="Enter player Name" defaultValue={user?name:''} maxLength="30" className="inputjoin" onChange={(e) => { setName(e.target.value) }}
                                    />


                                    <input type="text" ref={emailInput} defaultValue={user?email:''} placeholder="Player Email" className="inputjoin" onChange={(e) => { setEmail(e.target.value) }} />
                                    
                                    {email === 'invalid' ? 
                                    <p style={{fontSize:12 , color:'red'}}>Email is invalid!</p>
                                    :null}

                                    {teams ?
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={6}>
                                                Select Team
                                </Form.Label>

                                            <Col sm={6}  >
                                                <Form.Check
                                                    type="radio"
                                                    defaultChecked
                                                    label={teams[0].name.length ? teams[0].name : teams[0].headerName}
                                                    name="formHorizontalRadios"
                                                    id={teams[0].id}
                                                    onChange={(e) => { setSelectedTeam(e.target.id) }}

                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label={teams[1].name.length ? teams[1].name : teams[1].headerName}
                                                    name="formHorizontalRadios"
                                                    onChange={(e) => { setSelectedTeam(e.target.id) }}
                                                    id={teams[1].id}
                                                />
                                            </Col>

                                        </Form.Group>
                                        : null}

                                    <Form.Group as={Row}>
                                        <Form.Label column sm={4}>
                                            Select Avatar
                                </Form.Label>

                                        <Col sm={8}  >
                                            <Row>
                                                {avatars.current ? avatars.current.map(avatar => (
                                                    <img src={avatar} className={avatar === selectedAvatar ? 'slectedimg' : ''} height={50} width={50} alt="avatar" onClick={(e) => { setSelectedAvatar(e.target.src) }} style={{ padding: "1px" }} />


                                                ))

                                                    : null}



                                            </Row>

                                        </Col>

                                    </Form.Group>


                                    <button className={`btn btn-primary blue-color-button modelYesbtn  modal-confirm`} onClick={() => { addPlayer() }}>Yes</button>
                                    <button className="btn btn-default modelNobtn modal-dismiss" onClick={closePlayerModal}>No</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default JoinPlayerModal
